import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Component/Common/Navbar";
import Dashboard from "./Component/Pages/Dashboard";
import CreateDasboard from "./Component/Pages/CreateTemplate/Sidebar";
import ApplyDashboard from "./Component/Pages/ApplyTemplate/Sidebar";
import Profile from "./Component/Pages/Profile/Profile";
import ExtractedComponents from "./Component/Pages/ExtractPdf/ExtractedComponents";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { useState } from "react";
import CreateTemplate from "./Component/Pages/CreateTemplate";
import ApplyTemplate from "./Component/Pages/ApplyTemplate";
import ExtractPdf from "./Component/Pages/ExtractPdf/Sidebar";

const THEME = createTheme({
  typography: {
    fontFamily: `"Source Sans Pro", sans-serif`,
  },
});

function App() {
  const [userName, setUserName] = useState(null);
  return (
    <ThemeProvider theme={THEME}>
      <div className="App">
        <BrowserRouter>
          <Navbar userName={userName} />
          <Routes>
            <Route path="/" element={<Dashboard setUserName={setUserName} />} />
            <Route path="/components" element={<ExtractedComponents />} props />
            <Route path="/create-template" element={<CreateTemplate />} />
            <Route path="/apply-template" element={<ApplyTemplate />} />
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route path="/dashboard/extract" element={<ExtractPdf />} />
            <Route path="/dashboard/create" element={<CreateDasboard />} />
            <Route path="/dashboard/apply" element={<ApplyDashboard />} />

            <Route path="*" element={<p>404</p>} />
          </Routes>
        </BrowserRouter>
        <Toaster position="bottom-right" reverseOrder={false} gutter={8} />
      </div>
    </ThemeProvider>
  );
}

export default App;
