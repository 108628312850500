import Styled from "styled-components";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import HEADERBG from "../../../Assets/Icons/headerBG.svg";

export const HeaderSection = styled(Grid)({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const ServiceContainer = styled(Grid)({
  paddingTop: "48px",
  paddingBottom: "48px",
  paddingLeft: "56px",
  paddingRight: "56px",
  textAlign: "center",
  border: "3px solid #003AA6",
  borderRadius: "30px",
  background: "#F9F9F9 0% 0% no-repeat padding-box",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const ServiceHeading = styled(Grid)({
  fontSize: "28px",
  fontWeight: "700",
});

export const HeaderContainer = styled(Grid)({
  backgroundImage: `url(${HEADERBG})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingTop: "112px",
  paddingBottom: "80px",
  paddingLeft: "120px",
  paddingRight: "120px",
  display: "flex",
});

export const StyledInput = Styled.input`
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 2px solid #003AA6;
border-radius: 10px;
outline:none;
width:100%;
padding:5px 10px;
  `;

export const StyledButton = Styled.button`
    display: block;
    width: 270px;
    line-height: 2;
    background-color: #003aa6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
  `;

export const StyledSpan = Styled.span`
    color:blue;
    cursor:pointer;
  `;
