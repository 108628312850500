import axios from "axios"
import { BASE_URL } from "../../../Utils"

export const signUpApi = async ({params}) => {
    return await axios.post(BASE_URL + "/signup", params)
}

export const otpVerificationApi = async ({params}) => {
    return await axios.post(BASE_URL + "/verification-otp", params)
}

export const resendOtpApi = async ({params}) => {
    return await axios.post(BASE_URL + "/resend-otp", params)
}

export const loginApi = async ({params}) => {
    return await axios.post(BASE_URL + "/login", params)
}