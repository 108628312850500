import React, { useState, createContext } from "react";
import { useLocation } from "react-router";
import { Grid } from "@mui/material";
import { HeaderContainer, HeaderSection, ServiceContainer } from "./Style";
import Service from "./ChooseService";
import Footer from "../../Common/Footer";
import Feature from "../../Common/Feature";
import HowTo from "../../Common/HowTo";
import Header from "../../Common/Header";
import Auth from "./Auth";
import { getUser } from "./Util";
import { AUTH_CURRENT_PAGE } from "./Constant";

export const DataContext = createContext();

const Dashboard = ({ setUserName }) => {
  const user = getUser()
  const [stepIndex, setStepIndex] = useState(user ? 2 : 1);
  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode")
  const awsToken = params.get("aws_token")
  localStorage.setItem("AWS_SUBSCRIPTION_TOKEN", awsToken)
  const [currPage, setCurrPage] = useState(mode ? mode: AUTH_CURRENT_PAGE.LOGIN);
  return (
    <>
      <Grid>
        <HeaderContainer>
          <Header />
          <HeaderSection>
            <Grid width="80%">
              {stepIndex === 1 && (
                <ServiceContainer>
                  <Auth
                    setCurrPage={setCurrPage}
                    currPage={currPage}
                    setStepIndex={setStepIndex}
                    setUserName={setUserName}
                  />
                </ServiceContainer>
              )}
              {stepIndex === 2 && (
                <ServiceContainer>
                  <Service />
                </ServiceContainer>
              )}
            </Grid>
          </HeaderSection>
        </HeaderContainer>
        {stepIndex === 2 && <HowTo />}
        <Feature />
        <Footer />
      </Grid>
    </>
  );
};

export default Dashboard;
