import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BACK_ARROW_LOGO from "../../../Assets/Icons/back-arrow.svg";
import { ExtractorButton, ExtractorButtonImage } from "./Style";
import DataTable from "./DataTable";
import {
  CreateFlowButton,
  PageContainer,
  PageTab,
  PageTabSubSection,
  SideBarText,
  SidebarContainer,
} from "./Style";
import { toast } from "react-hot-toast";
import { MessageModal } from "./MessageModal";
import { getTemplateApi, saveSelectedTable } from "./Service";
import { INVALID_TOKEN_MESSAGE } from "./Constant";
import { isEmpty } from "lodash";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import axios from "axios";
import { BASE_URL } from "../../../Utils";

export const SelectTableView = ({ setStepIndex, tableData, template_id }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentTableId, setCurrentTableId] = useState(0);
  const [pagesArr, setPagesArr] = useState([]);
  const navigate = useNavigate();
  const [tableMaping, settableMaping] = useState({});

  useEffect(() => {
    const count = {};
    const startPoint = [];
    const tableIds = [];
    for (let i = 0; i < tableData.length; i++) {
      const element = tableData[i];
      if (count[element?.parsing_report?.page]) {
        count[element?.parsing_report?.page] += 1;
      } else {
        count[element?.parsing_report?.page] = 1;
        startPoint[element?.parsing_report?.page] = i;
      }
    }

    let pageTableID = {};

    for (let i = 0; i < tableData.length; i++) {
      if (pageTableID[tableData[i]?.parsing_report?.page]) {
        pageTableID[tableData[i]?.parsing_report?.page].push(
          tableData[i].table_id
        );
      } else {
        pageTableID[tableData[i]?.parsing_report?.page] = [
          tableData[i].table_id,
        ];
      }
    }

    let tableMapping2 = {};
    for (let i = 0; i < tableData.length; i++) {
      tableMapping2[tableData[i].table_id] = tableData[i].table;
    }

    settableMaping(tableMapping2);

    const pages = [];
    for (const key in count) {
      pages.push({
        pageNumber: key,
        tables: count[key],
        isOpen: false,
        startPoint: pageTableID[key],
      });
    }
    setPagesArr(pages);
  }, []);

  const pageClickHandler = (clickedIndex) => {
    const data = [...pagesArr];
    data.map((item, index) => {
      if (index !== clickedIndex) {
        item.isOpen = false;
      } else {
        item.isOpen = !item.isOpen;
      }
    });
    setPagesArr(data);
  };

  const getSelectedTables = async () => {
    try {
      let response = await getTemplateApi({ template_id });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setSelectedIds(response.data.template.selected_tables);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  useEffect(() => {
    getSelectedTables();
  }, []);

  const checkboxHandler = (id) => {
    if (selectedIds.includes(parseInt(id))) {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSaveSelectedtable = async () => {
    let payload = {
      template_id: template_id,
      table_ids: selectedIds,
    };
    try {
      let response = await saveSelectedTable({ payload });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setStepIndex(1);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const HandleMergeTables = async (e) => {
    e.preventDefault();
    const data = {
      template_id: template_id,
      table_details: tableData.filter((table) => {
        return selectedIds.includes(parseInt(table.table_id));
      }),
    };

    axios
      .post(
        BASE_URL + "/template-extraction/merge-tables",
        {
          ...data,
        },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          let message = response.data.message;
          toast.error(message);
          if (message === INVALID_TOKEN_MESSAGE) {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          toast.success(response.data.message);
          navigate("/dashboard/create");
        }
      })
      .catch((error) => {
        let message = error.response.data.error;
        toast.error(message);
      });
  };

  return (
    <Grid display={"flex"} width="100%" marginTop="65px">
      {!isEmpty(tableData) ? (
        <>
          <Grid sx={{ minWidth: "20%" }}>
            <SidebarContainer>
              <SideBarText>SELECT EXTRACTED TABLES</SideBarText>
              <Grid style={{ overflowY: "auto" }} width="80%">
                {pagesArr.map((page, index) => (
                  <Grid ml={5}>
                    <PageTab
                      page={page}
                      onClick={() => pageClickHandler(index)}
                    >
                      {Number(page.pageNumber) === 99
                        ? "Stock Data"
                        : Number(page.pageNumber) === 100
                        ? "Transcation Details"
                        : "Page " + (Number(page.pageNumber) + 1)}
                    </PageTab>
                    {page.isOpen && (
                      <PageTabSubSection>
                        {Array(page.tables)
                          .fill(null)
                          .map((u, i) => (
                            <PageContainer
                              onClick={() =>
                                setCurrentTableId(page.startPoint[i])
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(
                                  page.startPoint[i]
                                )}
                                onChange={() => {
                                  checkboxHandler(page.startPoint[i]);
                                }}
                                style={{ marginRight: "4px" }}
                              ></input>
                              Table {i + 1}
                            </PageContainer>
                          ))}
                      </PageTabSubSection>
                    )}
                  </Grid>
                ))}
              </Grid>
            </SidebarContainer>
          </Grid>
          <Grid flex={1} background="#f9f9f9" minWidth={"80%"}>
            <Grid p={3} pr={1}>
              <Grid display={"flex"} justifyContent="center" mb={5}>
                <CreateFlowButton>TEMPLATE CREATION</CreateFlowButton>
              </Grid>
              <Grid height="465px" px={10} mb={2}>
                {tableData.length > 0 && (
                  <DataTable props={tableMaping[currentTableId]} />
                )}
              </Grid>
              <Grid display="flex" justifyContent={"center"}>
                <ExtractorButton
                  variant="contained"
                  onClick={handleSaveSelectedtable}
                >
                  <ExtractorButtonImage
                    src={BACK_ARROW_LOGO}
                    alt="#"
                    width={"12"}
                    height="12"
                  />
                  Save & Continue
                </ExtractorButton>

                <ExtractorButton
                  variant="contained"
                  // onClick={handleSaveSelectedtable}
                  onClick={HandleMergeTables}
                  disabled={selectedIds?.length <= 1}
                >
                  <CallMergeIcon
                    style={{
                      marginRight: "0.3rem",
                    }}
                  />
                  Merge Tables
                </ExtractorButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <MessageModal
          modalHeading={
            "This PDF doesn't contains any table or the pdf still in pending phase"
          }
          saveHandler={handleSaveSelectedtable}
        />
      )}
    </Grid>
  );
};
