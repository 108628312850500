import { Grid } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Grid
      display="flex"
      alignItems={"center"}
      justifyContent="space-between"
      height="70px"
      color="#757575"
      mx={10}
    >
      <Grid fontSize="1em">
        © 2022 PdfExtractor AG — Made for the people of the internet.
      </Grid>
      <Grid display="flex" fontSize="1em">
        <Grid>Privacy Notice</Grid>
        <Grid mx={4}>Terms & Conditions</Grid>
        <Grid mr={4}>Contact Us</Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
