import React from "react";
import "../../../index.css";
import { Grid } from "@mui/material";
import {
  ExtractorButton,
  ExtractorButtonImage,
  TextDownloadAnchor,
} from "./Style";
import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";

export default function TextModal(props) {
  const downloadSelected = () => {
    const element = document.createElement("a");
    const file = new Blob([window.getSelection().toString()], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "data.txt";
    document.body.appendChild(element);
    element.click();
  };
  return (
    <Grid p={3} pr={1}>
      <Grid
        height="400px"
        sx={{ overflowY: "scroll" }}
        pr={1}
        pb={1}
      >
        <pre>{props.props}</pre>
      </Grid>
      <Grid position={"relative"}>
        <Grid
          position="absolute"
          top="50px"
          left="30%"
          display="flex"
          justifyContent={"center"}
        >
          <ExtractorButton variant="contained" onClick={downloadSelected}  width="280px">
            <ExtractorButtonImage
              src={DOWNLOAD_LOGO}
              alt="#"
              width={"12"}
              height="12"
            />
            Download Selected
          </ExtractorButton>
          <ExtractorButton variant="contained"  width="280px">
            <ExtractorButtonImage
              src={DOWNLOAD_LOGO}
              alt="#"
              width={"12"}
              height="12"
            />
            <TextDownloadAnchor
              download
              href={URL.createObjectURL(new Blob([props.props]))}
            >
              Download All
            </TextDownloadAnchor>
          </ExtractorButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
