import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  BackBtn,
  FileInput,
  FileInputLabel,
  RefreshBtn,
  S3Input,
  ServiceButton,
} from "./Style";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BASE_URL } from "../../../Utils";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import Jobs from "./Jobs";
import { DASHBOARD_FILTERS } from "./Util";
import ApplyModal from "./ApplyModal";

const Sidebar = () => {
  const [curTab, setCurTab] = useState("ALL");
  const [applyOpen, setApplyOpen] = useState(false);
  const [jobsData, setJobData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editJobId, setEditJobId] = useState(null);
  const [allJobsData, setAllJobsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sidebarTabs = ["ALL", "PENDING", "IN_PROGRESS", "COMPLETED"];

  useEffect(() => {
    fetchJobList();
  }, []);
  const fetchJobList = async () => {
    try {
      setLoading(true);
      let response = await axios.post(
        BASE_URL + "/job-scheduling/get-job",
        {},
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
           let message = response.data.message;
      toast.error(message);
      if (message === "Invalid Access Token.. Please login") {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      };
      } else {
        setAllJobsData(response.data.jobs);
        if (curTab === "ALL") {
          setJobData(response.data.jobs);
        } else {
          const newData = response.data.jobs.filter(
            (data) => data.job_status === curTab
          );
          setJobData(newData);
        }
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };

  const refreshHandler = () => {
    fetchJobList();
  };

  useEffect(() => {
    if (curTab === "ALL") {
      fetchJobList();
    } else {
      const newData = allJobsData.filter((data) => data.job_status === curTab);
      setJobData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curTab]);

  const handleRemoveJob = async (e) => {
    e.preventDefault();
    console.log(e.target.name);
    let job_id = e.target.name;
    try {
      setLoading(true);
      let response = await axios.post(
        BASE_URL + "/job-scheduling/delete-job",
        { job_id: job_id },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
           let message = response.data.message;
      toast.error(message);
      if (message === "Invalid Access Token.. Please login") {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      };
      } else {
        const newData = jobsData.filter((data) => data.id != job_id);
        setJobData(newData);
        setTimeout(() => {
          setLoading(false);
          toast.success("Job Removed Successfully");
        }, 1000);
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <Grid
      display={"flex"}
      width="100%"
      marginTop="65px"
      overflow={"hidden"}
      height="89.5vh"
    >
      <Grid
        sx={{ minWidth: "18%" }}
        display="flex"
        justifyContent="space-between"
        overflow={"hidden"}
      >
        <Grid
          width="100%"
          height="89.5vh"
          bgcolor="#003AA6"
          pt={4}
          pb={2}
          display="flex"
          flexDirection={"column"}
          overflow={"hidden"}
          borderRadius={"0 16px 16px 0"}
        >
          {sidebarTabs.map((tab) => (
            <Grid
              fontSize={"20px"}
              color="#ffffff"
              bgcolor={curTab === tab && "rgb(255,255,255,0.5)"}
              pl={2}
              py={1}
              style={{ cursor: "pointer" }}
              onClick={() => setCurTab(tab)}
            >
              {DASHBOARD_FILTERS[tab]}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid  width="88%">
        <Grid my={7}>
          <ServiceButton onClick={() => setApplyOpen(true)}>
            APPLY TEMPLATE
          </ServiceButton>
          <RefreshBtn onClick={() => refreshHandler()}>Refresh</RefreshBtn>
          <BackBtn onClick={() => navigate("/")}>Back</BackBtn>
        </Grid>
        <Grid display="flex" justifyContent={"center"}>
          {loading ? (
            <Grid margin="125px auto">
              <CircularProgress size={25} />
            </Grid>
          ) : jobsData?.length === 0 ? (
            <Grid margin="125px auto">No records found.</Grid>
          ) : (
            <Jobs
              setEditJobId={setEditJobId}
              setIsEdit={setIsEdit}
              setIsUpdate={setIsUpdate}
              jobsData={jobsData}
              handleRemoveJob={handleRemoveJob}
              setOpen={setApplyOpen}
            />
          )}
        </Grid>
      </Grid>

      {applyOpen && (
        <ApplyModal
          open={applyOpen}
          handleClose={() => {setApplyOpen(false); setIsUpdate(false); }}
          fetchJobList={fetchJobList}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          editJobId={editJobId}
        />
      )}
    </Grid>
  );
};

export default Sidebar;
