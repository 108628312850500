import { styled } from "@mui/material/styles";
import Styled from "styled-components";
import { AppBar, Button } from "@mui/material";

export const StyledAppBar = styled(AppBar)({
  background: "#fff",
  boxShadow: "0px 2px 5px #00000014",
  color: "#000000",
  padding: "0 70px",
  position: "fixed",
});

export const LoginButton = styled(Button)({
  margin: "16px 0",
  color: "#757575",
  fontSize: "16px",
});

export const LogoutButton = Styled.button`
  border-radius: 8px;
  height: 2.5rem;
  width: 5rem;
  margin-left: 2rem;
  color: white;
  border: none;
  background-color: #003aa6;
  cursor: pointer;
`;
