import { Grid } from "@mui/material";
import React from "react";
import { AUTH_CURRENT_PAGE } from "./Constant";
import { StyledSpan } from "./Style";

const Landing = ({ setCurrPage }) => {
  return (
    <Grid width="100%">
      <Grid fontSize={"22px"} fontWeight="700">
        Thank You for Creating your Account!!!
      </Grid>
      <Grid fontWeight={"700"} fontSize={"14px"} mt={2}>
        <StyledSpan onClick={() => setCurrPage(AUTH_CURRENT_PAGE.LOGIN)}>
          Login
        </StyledSpan>
        your account to Continue
      </Grid>
    </Grid>
  );
};

export default Landing;
