import axios from "axios";
import { BASE_URL } from "../../../Utils";

export const getUser = async () => {
  return await axios.get(BASE_URL + "/get-me", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};
