import React, { useState } from "react";
import { CircularProgress, Grid, Modal } from "@mui/material";
// import Templates from "./Templates";
import { Box } from "@mui/system";
import {
  BackBtn,
  FileInput,
  FileInputLabel,
  RefreshBtn,
  S3Input,
  ServiceButton,
} from "./Style";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Pdf from "./Pdf";
import ExtractPdf from "./ExtractPdf";
import { DASHBOARD_FILTERS } from "./Util";

const Sidebar = () => {
  const [curTab, setCurTab] = useState("ALL");
  const [extractOpen, setExtractOpen] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [allPdfData, setAllPdfData] = useState([]);
  const navigate = useNavigate();
  const sidebarTabs = ["ALL", "IN_PROGRESS", "COMPLETED", "ERROR"];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPdfList();
  }, []);

  const fetchPdfList = async () => {
    setLoading(true);
    try {
      let response = await axios.post(
        BASE_URL + "/extract/getpdfdata",
        {},
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        // if (message === "Invalid Access Token.. Please login") {
        //   sessionStorage.clear();
        //   navigate("/");
        //   window.location.reload();
        // }
      } else {
        setAllPdfData(response.data);
        if (curTab === "ALL") {
          setPdfData(response.data);
        } else {
          const newData = response.data.filter(
            (data) => data.pdf_status === curTab
          );
          setPdfData(newData);
        }
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.error;
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (curTab === "ALL") {
      fetchPdfList();
    } else {
      const newData = allPdfData.filter((data) => data.pdf_status === curTab);
      setPdfData(newData);
    }
  }, [curTab]);

  const refreshHandler = () => {
    fetchPdfList();
  };

  const handleRemovePdf = async (e) => {
    e.preventDefault();
    let pdf_id = Number(e.target.name);
    try {
      setLoading(true);
      let response = await axios.post(
        BASE_URL + "/extract/delete-pdf-data",
        { pdf_id },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        const newData = pdfData.filter((data) => data.id != pdf_id);
        setPdfData(newData);
        setTimeout(() => {
          setLoading(false);
          toast.success("Template Removed Successfully");
        }, 1000);
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };
  return (
    <Grid
      display={"flex"}
      width="100%"
      marginTop="65px"
      overflow={"hidden"}
      height="89.5vh"
    >
      <Grid
        sx={{ minWidth: "18%" }}
        display="flex"
        justifyContent="space-between"
        overflow={"hidden"}
      >
        <Grid
          width="100%"
          height="89.5vh"
          bgcolor="#003AA6"
          pt={4}
          pb={2}
          display="flex"
          flexDirection={"column"}
          overflow={"hidden"}
          borderRadius={"0 16px 16px 0"}
        >
          {sidebarTabs.map((tab) => (
            <Grid
              fontSize={"20px"}
              color="#ffffff"
              bgcolor={curTab === tab && "rgb(255,255,255,0.5)"}
              pl={2}
              py={1}
              style={{ cursor: "pointer" }}
              onClick={() => setCurTab(tab)}
            >
              {DASHBOARD_FILTERS[tab]}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid  width="88%">
        <Grid my={7}>
          <ServiceButton onClick={() => setExtractOpen(true)}>
            EXTRACT PDF
          </ServiceButton>
          <RefreshBtn onClick={() => refreshHandler()}>Refresh</RefreshBtn>
          <BackBtn onClick={() => navigate("/")}>Back</BackBtn>
        </Grid>
        <Grid display="flex" justifyContent={"center"}>
          {loading ? (
            <Grid margin="125px auto">
              <CircularProgress size={25} />
            </Grid>
          ) : pdfData?.length === 0 ? (
            <Grid margin="125px auto">No records found.</Grid>
          ) : (
            <Pdf pdfData={pdfData} handleRemovePdf={handleRemovePdf} />
          )}
        </Grid>
      </Grid>

      {extractOpen && (
        <ExtractPdf
          open={extractOpen}
          handleClose={() => setExtractOpen(false)}
          fetchPdfList={fetchPdfList}
        />
      )}
    </Grid>
  );
};

export default Sidebar;
