import moment from "moment";
import * as yup from "yup";

export const DASHBOARD_FILTERS = {
  ALL: "All",
  PENDING: "Pending",
  IN_PROGRESS: "In-Progress",
  COMPLETED: "Completed",
  INITIATED: "Initiated",
  ERROR: "Error",
};

export const APPLY_SIDEBAR_TABS = [
  "ALL",
  "INITIATED",
  "PENDING",
  "IN_PROGRESS",
  "COMPLETED",
];

export const COLS = [
  {
    HEADING: "Job Name",
    WIDTH: "600px",
  },
  {
    HEADING: "Scheduled Date",
    WIDTH: "250px",
  },
  {
    HEADING: "Scheduled Time",
    WIDTH: "250px",
  },
  {
    HEADING: "Status",
    WIDTH: "250px",
  },
  {
    HEADING: "Action",
    WIDTH: "250px",
  },
];

export const SIMPLIFY_STATUS = (status) => DASHBOARD_FILTERS[status];

export const SIMPLIFY_DATE = (date) =>
  moment(date.split("T")[0]).format("DD MMMM YYYY");

export const SIMPLIFY_DATE_02 = (date) =>
  moment(date.split(" ")[0]).format("DD MMMM YYYY");

export const SIMPLIFY_TIME = (time) =>
  time.split("T")[1].split(":")[0] + ":" + time.split("T")[1].split(":")[1];

export const SIMPLIFY_TIME_02 = (time) =>
  time.split(" ")[1].split(":")[0] + ":" + time.split(" ")[1].split(":")[1];

export const SIMPLIFY_PERIOD = (period) => period && "(" + period + ")";

export const applyTemplateSchema = yup.object().shape({
  job_name: yup.string().required("Job Name is Required."),
  input_type: yup
    .string()
    .required("Apply Type [Single / Multiple] is Required."),
    service_type: yup
    .string()
    .required("File [Upload / S3] is Required."),
});
