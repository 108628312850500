import { Button, DialogTitle } from "@mui/material";
import Styled from "styled-components";
import { styled } from "@mui/material";
import { Box } from "@mui/material";

export const FileInput = Styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
export const FileInputLabel = Styled.label`
  cursor: pointer;
  border-radius: 20px;
  border:${(props) =>
    props.s3Link ? "1px solid #d1cfcf" : "1px solid #003AA6"};
  line-height:34px;
  background: ${(props) => (props.s3Link ? "#d1cfcf" : "#003AA6")};
  color:#fff;
  margin-right:16px;
   width:90px;
   text-align:center;
`;
export const S3Input = Styled.input`
outline:none;
border-radius:20px;
color:#003AA6;
padding-left:10px;
border:${(props) => (props.file ? "1px solid #d1cfcf" : "1px solid #003AA6")};
width:100%;

line-height:34px;
background: ${(props) => (props.file ? "#d1cfcf" : "none")};
`;
export const RefreshBtn = Styled.button`
position: absolute;
    right: 190px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const BackBtn = Styled.button`
position: absolute;
    right: 100px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const ServiceButton = Styled.button`
display: block;
width: 270px;
line-height: 2;
background-color: #003aa6;
color: #fff;
outline: none;
border-radius: 10px;
cursor: pointer;
border: none;
box-shadow: 0px 3px 6px #00000052;
user-select: none;
margin: 0 auto;
`;

export const ModalButton = Styled.button`
display: block;
  width: 150px;
  line-height: 2;
  font-size: 12px;
  background-color: #003aa6;
  color: #fff;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 3px 6px #00000052;
  user-select: none;
  margin: 0 auto;
`;

export const AuthContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  boxShadow:
    "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
  padding: "32px",
});

export const StyledDialogTitle = styled(DialogTitle)({
  color: "#003AA6",
  fontSize: "24px",
  fontWeight: "700",
  textAlign: "center",
});

export const StyledDialogErrorDescription = styled(DialogTitle)({
  color: "red",
  fontSize: "20px",
  fontWeight: "600",
  textAlign: "center",
});

export const ResultModalButton = styled(Button)({
  background: "#003AA6",
  color: "#ffffff",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "20px",
  width: "150px",
  margin: "0 10px",
});

export const ExtractorButton = styled(Button)`
  background: #003aa6;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: ${(props) => (props.width ? props.width : "180px")};
  margin: 0 10px;
`;

export const ExtractorButtonImage = Styled.img`
  margin-right:10px
  `;

export const TextDownloadAnchor = Styled.a`
  text-decoration: none;
  color: #ffffff
  `;

export const EditTableButton = styled(Button)({
  background: "#00266D",
  color: "#ffffff",
  boxShadow: "0px 4px 6px #00000066",
  borderRadius: "20px",
  width: "180px",
  margin: "0 10px",
  fontSize: "12px",
});

export const EditTableSubButton = styled(Button)({
  background: "#00266D",
  color: "#ffffff",
  boxShadow: "0px 4px 6px #00000066",
  borderRadius: "20px",
  margin: "0 1px",
  width: "5px",
  height: "25px",
  paddingLeft: "2px",
  paddingRight: "2px",
  fontSize: "12px",
});

export const TableModalButton = styled(Button)({
  background: "#00266D",
  color: "#ffffff",
  boxShadow: "0px 4px 6px #00000066",
  borderRadius: "20px",
  width: "100px",
  margin: "0 10px",
  fontSize: "12px",
});
