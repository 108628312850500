import moment from "moment";

export const DASHBOARD_FILTERS = {
  ALL: "All",
  PENDING: "Pending",
  IN_PROGRESS: "In-Progress",
  COMPLETED: "Completed",
  INITIATED: "Initiated",
  ERROR: "Error",
};

export const CREATE_SIDEBAR_TABS = [
  "ALL",
  "INITIATED",
  "PENDING",
  "IN_PROGRESS",
  "COMPLETED",
];

export const COLS = [
  {
    HEADING: "Template Name",
    WIDTH: "600px",
  },
  {
    HEADING: "Creation Date",
    WIDTH: "250px",
  },
  {
    HEADING: "Status",
    WIDTH: "250px",
  },
  {
    HEADING: "Action",
    WIDTH: "250px",
  },
];

export const SIMPLIFY_STATUS = (status) => DASHBOARD_FILTERS[status];
export const SIMPLIFY_DATE = (date) =>
  moment(date?.split("T")[0])?.format("DD MMMM YYYY");
