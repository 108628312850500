import Styled from "styled-components";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const Label = styled(Grid)({
  fontSize: "18px",
  marginBottom: "8px",
  fontWeight: "600",
});

export const Input = Styled.input`
  border: 1px solid rgb(218 212 212);
  font-size: 18px;
  border-radius: 4px;
  padding: 6px 10px;
  outline: none;
  font-style: italic;
  width: ${(props) => (props.width ? props.width : "270px")};
    `;

export const StyledBack = styled(Grid)({
  position: "absolute",
  top: "90px",
  right: "162px",
  cursor: "pointer",
});
