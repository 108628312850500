import {
    Box,
    FormControl,
    Grid,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
  } from "@mui/material";
  import React, { useEffect, useRef } from "react";
  import { useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
  import BACK_ARROW_LOGO from "../../../Assets/Icons/back-arrow.svg";
  import { ExtractorButton, ExtractorButtonImage } from "../Dashboard/Style";
  import DataTable from "./DataTable";
  import { CustomKeyButton, CustomKeyModalButton, ModalButton, ModalContainer } from "./Style";
  import axios from "axios";
  import { toast } from "react-hot-toast";
  import { PDFViewer } from "./PDFViewer";
  import { BASE_URL } from "../../../Utils";
  import { create } from "yup/lib/Reference";

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };
  

export const MessageModal = ({ modalHeading, saveHandler }) => {
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid fontSize={"22px"}>{modalHeading}</Grid>
          <Grid display="flex" mt={3}>
            <ModalButton onClick={saveHandler}>
              Save & Continue
            </ModalButton>
          </Grid>
        </Box>
      </Modal>
    );
  };