/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";
import EDIT_LOGO from "../../../Assets/Icons/edit.svg";
import CLIPBOARD_LOGO from "../../../Assets/Icons/clipboard.svg";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import {
  AuthContainer,
  ExtractorButton,
  ExtractorButtonImage,
  TableModalButton,
  TextDownloadAnchor,
} from "./Style";
import DataTable from "./DataTable";
import { TableContext } from "./ExtractedComponents";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import SaveTemplateDialog from "./SaveTemplateDialog";
export default function TableModal({ props, setTarget_table }) {
  const [workflow, setWorkflow] = useState([]);
  const location = useLocation();
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const templateID = location.state.templateID;
  const [applyWorkOpen, setApplyWorkOpen] = useState(false);
  const {
    currentTableId,
    setIsTableEditMode,
    isTableEditMode,
    isTemplateFlow,
    isApplyTemplate,
  } = useContext(TableContext);

  const fetchWorkflow = async () => {
    try {
      let response = await axios.get(BASE_URL + "/extract/workflow", {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        setWorkflow(response.data);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleBack = async (template_id) => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/get-template",
        { template_id },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        sessionStorage.setItem(
          "upload-data",
          `data:application/pdf;base64,${
            response.data.template.pdf_file.split("'")[1]
          }`
        );
        navigate("/create-template", {
          state: {
            template_id: template_id,
            extracted_tables: response.data.template.extracted_tables,
            pair_feature_list: response.data.template.pair_feature_list,
            selectedTablesIds: response.data.template.selected_tables,
            selectedPairsIds: response.data.template.selected_pairs,
            flow: "BACK"
          },
        });
        // setStepIndex(1);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  useEffect(() => {
    fetchWorkflow();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Grid p={3} pr={1}>
        <Grid height="380px" sx={{ overflowY: "auto" }} pr={1} pb={1}>
          <DataTable
            setWorkflow={setWorkflow}
            props={props[currentTableId].table}
          />
        </Grid>
        <Grid position={"relative"}>
          <Grid
            position="absolute"
            top="50px"
            left={isTemplateFlow ? "12%" : "30%"}
            display="flex"
            justifyContent={"center"}
          >
            {!isApplyTemplate && !isTemplateFlow && (
              <ExtractorButton
                variant="contained"
                onClick={
                  isTableEditMode
                    ? () => {
                        fetchWorkflow();
                        setApplyWorkOpen(true);
                      }
                    : () => setIsTableEditMode(true)
                }
              >
                <ExtractorButtonImage
                  src={isTableEditMode ? CLIPBOARD_LOGO : EDIT_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                {isTableEditMode ? "Apply Workflow" : "Edit Table"}
              </ExtractorButton>
            )}

            {isTemplateFlow && !isTableEditMode && (
              <ExtractorButton
                variant="contained"
                onClick={
                  isTableEditMode
                    ? () => setApplyWorkOpen(true)
                    : () => setIsTableEditMode(true)
                }
              >
                <ExtractorButtonImage
                  src={isTableEditMode ? CLIPBOARD_LOGO : EDIT_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                {isTableEditMode ? "Apply Workflow" : "Edit Table"}
              </ExtractorButton>
            )}
            {isTemplateFlow && (
              <>
                {!isTableEditMode && (
                  <ExtractorButton
                    onClick={(e) => {
                      e.preventDefault();
                      setSaveTemplateOpen(true);
                    }}
                    variant="contained"
                  >
                    <ExtractorButtonImage
                      src={DOWNLOAD_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    <TextDownloadAnchor>Save Template</TextDownloadAnchor>
                  </ExtractorButton>
                )}
              </>
            )  
            }
             {isTemplateFlow && (
              <>
                {!isTableEditMode && (
                  <ExtractorButton
                    onClick={(e) => {
                      handleBack(templateID)
                    }}
                    variant="contained"
                  >
                    <ExtractorButtonImage
                      src={DOWNLOAD_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    <TextDownloadAnchor>Back</TextDownloadAnchor>
                  </ExtractorButton>
                )}
              </>
            )  
            }
            <>
                <CSVLink
                  data={props[currentTableId].table}
                  style={{ textDecoration: "none" }}
                >
                  <ExtractorButton variant="contained">
                    <ExtractorButtonImage
                      src={DOWNLOAD_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    <TextDownloadAnchor>Download Table</TextDownloadAnchor>
                  </ExtractorButton>
                </CSVLink>
              </>
            {applyWorkOpen && (
              <ApplyWorkflowModal
                open={applyWorkOpen}
                setOpen={setApplyWorkOpen}
                allWorkflow={workflow}
                props={props}
                setTarget_table={setTarget_table}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <SaveTemplateDialog
        open={saveTemplateOpen}
        setOpen={setSaveTemplateOpen}
        templateID={templateID}
      />
    </>
  );
}

const ApplyWorkflowModal = ({
  open,
  setOpen,
  allWorkflow,
  props,
  setTarget_table,
}) => {
  const [workflow, setWorkflow] = useState("");
  const [workflow_id, setWorkflow_id] = useState("");
  const [table_id, setTable_id] = useState("");
  const [table, setTable] = useState("");
  const [isApplyAll, setIsApplyAll] = useState(false);

  const request = {
    workflow_id: "",
    table_list: [],
  };

  const applyWorkflowHandler = async () => {
    request.workflow_id = workflow;
    if (!isApplyAll) {
      let temp = { table_id: "", table: [] };
      temp.table_id = table_id.table_id;
      temp.table = table_id.table;
      request.table_list.push(temp);
    } else {
      // eslint-disable-next-line array-callback-return
      props.map((eachTable) => {
        let temp = { table_id: "", table: [] };
        temp.table_id = eachTable.table_id;
        temp.table = eachTable.table;
        request.table_list.push(temp);
      });
    }
    // DO THE API INTEGRATION HERE...
    let response = await axios.post(
      BASE_URL + "/extract/tableupdationworkflow",
      request,
      {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      }
    );
    setOpen(false);
    setTarget_table(response.data.updated_tables_list);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid>
        <AuthContainer>
          <Grid fontSize={"28px"}>
            <Grid mb={2} fontSize={"22px"} textAlign={"center"}>
              APPLY WORKFLOW
            </Grid>
            <Grid>
              <Grid fontSize={"16px"} mr={2}>
                Choose Workflow
              </Grid>
              <Grid>
                <FormControl fullWidth size="small">
                  <Select
                    value={workflow}
                    onChange={(e) => setWorkflow(e.target.value)}
                  >
                    {allWorkflow.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid fontSize={"16px"} mr={2}>
                Apply To
              </Grid>
              <Grid>
                <FormControl fullWidth size="small">
                  <Select
                    value={table_id}
                    onChange={(e) => setTable_id(e.target.value)}
                    disabled={isApplyAll}
                  >
                    {props.map((table) => (
                      <MenuItem value={table}>
                        table {table.table_id + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                mt={1}
                display={"flex"}
                alignItems="center"
                fontSize={"16px"}
                fontWeight="700"
              >
                <Checkbox
                  size="small"
                  onChange={() => setIsApplyAll(!isApplyAll)}
                  sx={{ padding: "0", marginRight: "5px" }}
                ></Checkbox>{" "}
                Apply to All Tables
              </Grid>
            </Grid>
          </Grid>
          <Grid
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            mt={2}
          >
            <TableModalButton
              variant="contained"
              size="small"
              onClick={applyWorkflowHandler}
            >
              Apply
            </TableModalButton>
            <TableModalButton
              variant="contained"
              size="small"
              onClick={() => setOpen(false)}
            >
              Cancel
            </TableModalButton>
          </Grid>
        </AuthContainer>
      </Grid>
    </Modal>
  );
};
