import * as yup from "yup";

export const SERVICE_LIST = [
  {
    TEXT: "Extract PDF",
    ROUTE: "/dashboard/extract",
  },
  {
    TEXT: "Create Template",
    ROUTE: "/dashboard/create",
  },
  {
    TEXT: "Apply Template",
    ROUTE: "/dashboard/apply",
  },
];

export const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is Required."),
  password: yup.string().min(5).max(20).required("Password is Required."),
});

export const signupSchema = yup.object().shape({
  firstName: yup.string().max(25).required("First Name is Required."),
  lastName: yup.string().max(25).required("Last Name is Required."),
  email: yup.string().email().required("Email is Required."),
  password: yup.string().min(5).max(20).required("Password is Required."),
  conPassword: yup
    .string()
    .min(5)
    .max(20)
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password field is Required."),
});

export const getUser = () => sessionStorage.getItem("token")
