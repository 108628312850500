import React, { createContext, useEffect, useState } from "react";
import { Grid, Modal, Switch, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExtractionModal from "./ExtractionModal";
import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
import SAVE_WORKFLOW from "../../../Assets/Icons/save_workflow.svg";
import SAVE_CHANGES from "../../../Assets/Icons/save_changes.svg";
import DISCARD_CHANGES from "../../../Assets/Icons/discard_changes.svg";
import EXIT_EDIT_MODE from "../../../Assets/Icons/exit_edit.svg";
import MERGE_ROWS from "../../../Assets/Icons/merge.svg";
import ADD from "../../../Assets/Icons/add.svg";
import DELETE_ROWS from "../../../Assets/Icons/bin.svg";
import EDIT_LOGO from "../../../Assets/Icons/edit.svg";
import { EXTRACTED_PARTS } from "./Util";
import {
  AuthContainer,
  EditTableButton,
  EditTableSubButton,
  ExtractorButtonImage,
  TableModalButton,
} from "./Style";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import { toast } from "react-hot-toast";

export const TableContext = createContext();

export default function ExtractedComponents() {
  let location = useLocation();
  const navigate = useNavigate();
  const [isTemplateFlow] = useState(location?.state?.finalTables?.final_table);
  const isApplyTemplate = location?.state.final_merged_tables;
  const [currentTab, setCurrentTab] = useState(
    isTemplateFlow || isApplyTemplate
      ? EXTRACTED_PARTS.TABLE
      : EXTRACTED_PARTS.TEXT
  );
  const [currentTableId, setCurrentTableId] = useState(0);
  const [currentFinalTableId, setCurrentFinalTableId] = useState(
    isTemplateFlow ? isTemplateFlow[0]?.final_table_id : ""
  );
  const [isTableEditMode, setIsTableEditMode] = useState(false);
  const [editRowsToggle, setEditRowsToggle] = React.useState(false);
  const [editColsToggle, setEditColsToggle] = React.useState(true);
  const [axis, setAxis] = useState(1);
  const [target_index, setTarget_index] = useState([]);
  const [new_column_list] = useState([]);
  const [past_operation, setPast_operation] = useState([]);
  const [renameColOpen, setRenameColOpen] = useState(false);
  const [addColumnName, setAddColumnName] = useState(false);
  const [saveWorkOpen, setSaveWorkOpen] = useState(false);
  const [renameTarNames, setRenameTarNames] = useState([]);
  const [colCheckList, setColCheckList] = useState([]);
  const [rowCheckList, setRowCheckList] = useState([]);
  const [operationList, setOperationList] = useState();
  const [updateTables, setUpdateTables] = useState();
  const [pdfId] = useState(location.state.id);
  const [pagesArr, setPagesArr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  let tableData = location.state.extracted_tables
    ? location.state.extracted_tables
    : isApplyTemplate && !isTemplateFlow
    ? location.state.final_merged_tables
    : isTemplateFlow;
  const [target_table, setTarget_table] = useState(
    location.state.extracted_tables
      ? location.state.extracted_tables
      : isApplyTemplate && !isTemplateFlow
      ? location.state.final_merged_tables
      : isTemplateFlow
  );

  useEffect(() => {
    const count = {};
    const startPoint = [];
    for (let i = 0; i < tableData.length; i++) {
      const element = tableData[i];
      if (count[element?.parsing_report?.page]) {
        count[element?.parsing_report?.page] += 1;
      } else {
        count[element?.parsing_report?.page] = 1;
        startPoint[element?.parsing_report?.page] = i;
      }
    }

    const pages = [];
    for (const key in count) {
      pages.push({
        pageNumber: key,
        tables: count[key],
        isOpen: false,
        startPoint: startPoint[key],
      });
    }
    setPagesArr(pages);
  }, []);

  const pageClickHandler = (clickedIndex) => {
    const data = [...pagesArr];
    data.map((item, index) => {
      if (index !== clickedIndex) {
        item.isOpen = false;
      } else {
        item.isOpen = !item.isOpen;
      }
    });
    setPagesArr(data);
  };

  useEffect(() => {
    if (target_table?.length > 0) {
      let colArr = [];
      target_table[currentTableId]?.table[0]?.forEach((item) => {
        colArr.push({ checked: false });
      });
      setColCheckList(colArr);
      let rowArr = [];
      target_table[currentTableId]?.table?.forEach((item) => {
        rowArr.push({ checked: false });
      });
      setRowCheckList(rowArr);
      setPast_operation([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTableId]);

  const markCheckBoxUncheck = () => {
    let horChecklist = colCheckList;
    horChecklist.forEach((item) => (item.checked = false));
    setColCheckList(horChecklist);
    let verChecklist = rowCheckList;
    verChecklist.forEach((item) => (item.checked = false));
    setRowCheckList(verChecklist);
  };

  const updateTable = async (operation, value) => {
    let payload;
    if (operation === "SPLIT_COLUMN") {
      payload = {
        target_table: target_table[currentTableId].table,
        user_operation: {
          operation,
          target_index,
          axis,
          new_column_list,
          delimiter: value,
        },
        past_operation,
      };
    } else {
      payload = {
        target_table: target_table[currentTableId].table,
        user_operation: { operation, target_index, axis, new_column_list },
        past_operation,
      };
    }
    try {
      let response = await axios.post(
        BASE_URL + "/extract/tableupdation",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Operation performed successfully!");
        let tableData = target_table;
        tableData[currentTableId].table = response.data.updated_table;
        setTarget_table(tableData);
        setTarget_index([]);
        setRenameTarNames([]);
        setPast_operation([
          ...past_operation,
          response?.data?.operation_list[
            response.data.operation_list.length - 1
          ],
        ]);
        let colArr = [];
        response.data.updated_table[0].forEach((item) => {
          colArr.push({ checked: false });
        });
        setColCheckList(colArr);
        let rowArr = [];
        response.data.updated_table.forEach((item) => {
          rowArr.push({ checked: false });
        });
        setOperationList(response?.data.operation_list);
        setUpdateTables(response?.data.updated_table);
        setRowCheckList(rowArr);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleDiscard = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/extract/getpdfdata",
        { id: pdfId },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Changes discarded!");
        setTarget_table(response.data.extracted_tables);
        setTarget_index([]);
      }
      setIsTableEditMode(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleSave = async () => {
    let updatedData = {
      pdfdata_id: pdfId,
      table_id: target_table[currentTableId].table_id,
      new_table: target_table[currentTableId].table,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/extract/updatepdfdata",
        updatedData,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Changes saved successfully!");
        let response = await axios.post(
          BASE_URL + "/extract/getpdfdata",
          { id: pdfId },
          {
            headers: {
              authorization: sessionStorage.getItem("token"),
            },
          }
        );
        setTarget_table(response.data.extracted_tables);
        setTarget_index([]);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  const handleSaveTemplateFlow = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/save-flow",
        {
          workflow: operationList,
          final_table: updateTables,
          final_table_id: currentFinalTableId,
        },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Changes Saved Successfully");
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  const handleDiscardChangesTemplateFlow = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/get-final-table",
        { final_table_id: currentFinalTableId },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        // tableData = response.data.final_table;
        // setTarget_table(response.data.final_table);
        let tableData = target_table;
        tableData[currentTableId].table = response.data.final_table;
        setTarget_table(tableData);
        toast.success("Discard Changes");
        setTarget_index([]);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  const handleExitModeTemplateFlow = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/get-final-table",
        { final_table_id: currentFinalTableId },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        // tableData = response.data.final_table;
        // setTarget_table(response.data.final_table);
        let tableData = target_table;
        tableData[currentTableId].table = response.data.final_table;
        setTarget_table(tableData);
        setIsTableEditMode(false);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  return (
    <TableContext.Provider
      value={{
        currentTableId,
        setIsTableEditMode,
        isTableEditMode,
        target_index,
        setTarget_index,
        editColsToggle,
        editRowsToggle,
        renameTarNames,
        setRenameTarNames,
        colCheckList,
        rowCheckList,
        setColCheckList,
        setRowCheckList,
        isTemplateFlow,
        isApplyTemplate,
      }}
    >
      <Grid display="flex" alignItems={"center"} justifyContent="space-between">
        {currentTab === EXTRACTED_PARTS.TABLE && target_table.length > 0 && (
          <Grid
            sx={{ minWidth: "18%" }}
            height="100vh"
            bgcolor="#003AA6"
            pt={15}
            pb={2}
            pl={2}
            pr={1}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Grid fontSize={"22px"} color="#ffffff" mb={3}>
              {isTableEditMode ? "EDIT" : "SELECT"} TABLE
            </Grid>
            <Grid style={{ overflowY: "auto" }} width="-webkit-fill-available">
              {isTableEditMode ? (
                <Grid>
                  <Grid
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid color="#ffffff" fontSize={"14px"}>
                      EDIT ROWS
                    </Grid>
                    <Grid>
                      <Switch
                        checked={editRowsToggle}
                        size="small"
                        onChange={(event) => {
                          setEditRowsToggle(event.target.checked);
                          setAxis(0);
                          setTarget_index([]);
                          setEditColsToggle(false);
                        }}
                        color="default"
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  {editRowsToggle && (
                    <Grid>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => updateTable("MERGE")}
                        >
                          <ExtractorButtonImage
                            src={MERGE_ROWS}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Merge Rows
                        </EditTableButton>
                      </Grid>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => updateTable("DELETE")}
                        >
                          <ExtractorButtonImage
                            src={DELETE_ROWS}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Delete Rows
                        </EditTableButton>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid color="#ffffff" fontSize={"14px"}>
                      EDIT COLUMNS
                    </Grid>
                    <Grid>
                      <Switch
                        checked={editColsToggle}
                        size="small"
                        color="default"
                        onChange={(event) => {
                          setEditColsToggle(event.target.checked);
                          setAxis(1);
                          setTarget_index([]);
                          setEditRowsToggle(false);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  {editColsToggle && (
                    <Grid borderBottom={"1px solid #5597f4"}>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => updateTable("MERGE")}
                        >
                          <ExtractorButtonImage
                            src={MERGE_ROWS}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Merge Columns
                        </EditTableButton>
                      </Grid>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            setAddColumnName(true);
                          }}
                        >
                          <ExtractorButtonImage
                            src={ADD}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Add headers
                        </EditTableButton>
                      </Grid>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            setRenameColOpen(true);
                          }}
                        >
                          <ExtractorButtonImage
                            src={EDIT_LOGO}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Rename Column
                        </EditTableButton>
                      </Grid>
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <ExtractorButtonImage
                            src={DELETE_ROWS}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Split Columns
                        </EditTableButton>
                      </Grid>
                      {isOpen && (
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <EditTableSubButton
                            variant="contained"
                            onClick={() => updateTable("SPLIT_COLUMN", "(")}
                          >
                            ( )
                          </EditTableSubButton>
                          <EditTableSubButton
                            variant="contained"
                            disabled="true"
                            onClick={() => updateTable("SPLIT_COLUMN", "{")}
                          >
                            {"{ }"}
                          </EditTableSubButton>
                          <EditTableSubButton
                            variant="contained"
                            disabled="true"
                            onClick={() => updateTable("SPLIT_COLUMN", "[")}
                          >
                            [ ]
                          </EditTableSubButton>
                        </Grid>
                      )}
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => updateTable("DELETE")}
                        >
                          <ExtractorButtonImage
                            src={DELETE_ROWS}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Delete Columns
                        </EditTableButton>
                      </Grid>
                    </Grid>
                  )}
                  <Grid>
                    {!isTemplateFlow && (
                      <Grid my={2}>
                        <EditTableButton
                          variant="contained"
                          size="small"
                          onClick={() => setSaveWorkOpen(true)}
                        >
                          <ExtractorButtonImage
                            src={SAVE_WORKFLOW}
                            alt="#"
                            width={"12"}
                            height="12"
                          />
                          Save Workflow
                        </EditTableButton>
                      </Grid>
                    )}

                    <Grid my={2}>
                      <EditTableButton
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isTemplateFlow) {
                            handleSaveTemplateFlow();
                          } else {
                            handleSave();
                          }
                        }}
                      >
                        <ExtractorButtonImage
                          src={SAVE_CHANGES}
                          alt="#"
                          width={"12"}
                          height="12"
                        />
                        Save Changes
                      </EditTableButton>
                    </Grid>
                    <Grid my={2}>
                      <EditTableButton
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isTemplateFlow) {
                            handleDiscardChangesTemplateFlow();
                          } else {
                            handleDiscard();
                          }
                        }}
                      >
                        <ExtractorButtonImage
                          src={DISCARD_CHANGES}
                          alt="#"
                          width={"12"}
                          height="12"
                        />
                        Discard Changes
                      </EditTableButton>
                    </Grid>
                    <Grid my={2}>
                      <EditTableButton
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isTemplateFlow) {
                            handleExitModeTemplateFlow();
                          } else {
                            handleDiscard();
                          }
                        }}
                        // onClick={(e)=>{
                        //   e.preventDefault()
                        //   setIsTableEditMode(false)
                        // }}
                      >
                        <ExtractorButtonImage
                          src={EXIT_EDIT_MODE}
                          alt="#"
                          width={"12"}
                          height="12"
                        />
                        Exit Edit Mode
                      </EditTableButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : isTemplateFlow ? (
                tableData.map((table) => (
                  <Grid my={2.5}>
                    <Grid
                      onClick={() => {
                        setCurrentTableId(table.table_id);
                        setCurrentFinalTableId(table?.final_table_id);
                      }}
                    >
                      <img
                        src={TABLE_SELECT_LOGO}
                        alt="#"
                        width={"70"}
                        height="70"
                        style={{
                          display: "block",
                          margin: "0 auto",
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                    <Grid
                      color="#ffffff"
                      fontSize={"14px"}
                      textAlign="center"
                      mt={1}
                    >
                      Table {table.table_id + 1 + " "}
                    </Grid>
                  </Grid>
                ))
              ) : (
                pagesArr.map((page, index) => (
                  <Grid>
                    <Grid
                      sx={{ cursor: "pointer" }}
                      my={1}
                      color="white"
                      border={
                        page.isOpen ? "1px solid black" : "1px solid white"
                      }
                      borderRadius={"3px"}
                      width="100%"
                      px={1}
                      onClick={() => pageClickHandler(index)}
                    >
                      {Number(page.pageNumber) === 99
                        ? "Stock Data"
                        : "Page " + (Number(page.pageNumber) + 1)}
                    </Grid>
                    {page.isOpen && (
                      <Grid
                        color="#003AA6"
                        bgcolor={"#09097c"}
                        p={2}
                        border="1px solid blue"
                        borderRadius={"10px"}
                      >
                        {Array(page.tables)
                          .fill(null)
                          .map((u, i) => (
                            <Grid
                              bgcolor="#003AA6"
                              sx={{ cursor: "pointer" }}
                              my={0.5}
                              border={"1px solid #003AA6"}
                              color="white"
                              px={2}
                              onClick={() =>
                                setCurrentTableId(page.startPoint + i)
                              }
                            >
                              Table {i + 1}
                            </Grid>
                          ))}
                      </Grid>
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        )}

        <Grid
          bgcolor={"#F9F9F9"}
          sx={{ minWidth: "70%" }}
          flex={1}
          height="100vh"
          pt={15}
          pl={3}
          pr={4}
        >
          <Grid
            display={"flex"}
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid display={"flex"} justifyContent="center">
              {location.state.image_base64 && (
                <Grid
                  border={
                    location.state.image_base64.length === 0
                      ? "2px solid grey"
                      : "2px solid #003AA6"
                  }
                  sx={
                    location.state.image_base64.length === 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  bgcolor={
                    location.state.image_base64.length === 0
                      ? "#c9c4c4"
                      : currentTab === EXTRACTED_PARTS.IMAGE
                      ? "#003AA6"
                      : "#ffffff"
                  }
                  color={
                    location.state.image_base64.length === 0
                      ? "grey"
                      : currentTab === EXTRACTED_PARTS.IMAGE
                      ? "#ffffff"
                      : "#003AA6"
                  }
                  px={6}
                  borderRadius="18px 0px 0px 18px"
                  fontSize={"22px"}
                  onClick={() =>
                    location.state.image_base64.length > 0 &&
                    setCurrentTab(EXTRACTED_PARTS.IMAGE)
                  }
                >
                  IMAGES
                </Grid>
              )}
              {location.state.extracted_text && (
                <Grid
                  border="2px solid #003AA6"
                  sx={{ cursor: "pointer" }}
                  bgcolor={
                    currentTab === EXTRACTED_PARTS.TEXT ? "#003AA6" : "#ffffff"
                  }
                  color={
                    currentTab === EXTRACTED_PARTS.TEXT ? "#ffffff" : "#003AA6"
                  }
                  px={6}
                  fontSize={"22px"}
                  onClick={() => setCurrentTab(EXTRACTED_PARTS.TEXT)}
                >
                  TEXT
                </Grid>
              )}
              {isApplyTemplate && !isTemplateFlow && (
                <Grid
                  border={
                    target_table?.length === 0
                      ? "2px solid grey"
                      : "2px solid #003AA6"
                  }
                  sx={
                    target_table?.length === 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  bgcolor={
                    target_table?.length === 0
                      ? "#c9c4c4"
                      : currentTab === EXTRACTED_PARTS.TABLE
                      ? "#003AA6"
                      : "#ffffff"
                  }
                  color={
                    target_table?.length === 0
                      ? "grey"
                      : currentTab === EXTRACTED_PARTS.TABLE
                      ? "#ffffff"
                      : "#003AA6"
                  }
                  px={6}
                  borderRadius={"18px"}
                  fontSize={"22px"}
                  onClick={() =>
                    target_table?.length > 0 &&
                    setCurrentTab(EXTRACTED_PARTS.TABLE)
                  }
                >
                  FINAL TABLES
                </Grid>
              )}
              {!isApplyTemplate && (
                <Grid
                  border={
                    target_table?.length === 0
                      ? "2px solid grey"
                      : "2px solid #003AA6"
                  }
                  sx={
                    target_table?.length === 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                  bgcolor={
                    target_table?.length === 0
                      ? "#c9c4c4"
                      : currentTab === EXTRACTED_PARTS.TABLE
                      ? "#003AA6"
                      : "#ffffff"
                  }
                  color={
                    target_table?.length === 0
                      ? "grey"
                      : currentTab === EXTRACTED_PARTS.TABLE
                      ? "#ffffff"
                      : "#003AA6"
                  }
                  px={6}
                  borderRadius={isTemplateFlow ? "18px" : "0px 18px 18px 0px"}
                  fontSize={"22px"}
                  onClick={() =>
                    target_table.length > 0 &&
                    setCurrentTab(EXTRACTED_PARTS.TABLE)
                  }
                >
                  {isTemplateFlow ? "TEMPLATE CREATION" : "TABLES"}
                </Grid>
              )}
            </Grid>
            <Grid
              my="20px"
              bgcolor="#FFFFFF"
              boxShadow={"0px 3px 6px #00000029"}
              borderRadius="20px"
              width={"90%"}
            >
              <ExtractionModal
                currentTab={currentTab}
                data={
                  currentTab === EXTRACTED_PARTS.TABLE
                    ? target_table
                    : location.state
                }
                setTarget_table={setTarget_table}
              />
            </Grid>
          </Grid>
        </Grid>
        {renameColOpen && (
          <RenameColumnModal
            open={renameColOpen}
            setOpen={setRenameColOpen}
            renameTarNames={renameTarNames}
            currentTableId={currentTableId}
            target_table={target_table}
            target_index={target_index}
            axis={axis}
            past_operation={past_operation}
            setTarget_table={setTarget_table}
            setTarget_index={setTarget_index}
            setPast_operation={setPast_operation}
            markCheckBoxUncheck={markCheckBoxUncheck}
            setRenameTarNames={setRenameTarNames}
            setOperationList={setOperationList}
            setUpdateTables={setUpdateTables}
          />
        )}
        {addColumnName && (
          <AddColumnName
            open={addColumnName}
            setOpen={setAddColumnName}
            currentTableId={currentTableId}
            target_table={target_table}
            target_index={target_index}
            axis={axis}
            past_operation={past_operation}
            setTarget_table={setTarget_table}
            setTarget_index={setTarget_index}
            setPast_operation={setPast_operation}
            markCheckBoxUncheck={markCheckBoxUncheck}
            setRenameTarNames={setRenameTarNames}
            setOperationList={setOperationList}
            setUpdateTables={setUpdateTables}
          />
        )}
        {saveWorkOpen && (
          <SaveWorkflowModal
            open={saveWorkOpen}
            setOpen={setSaveWorkOpen}
            past_operation={past_operation}
          />
        )}
      </Grid>
    </TableContext.Provider>
  );
}
const AddColumnName = ({
  open,
  setOpen,
  target_table,
  currentTableId,
  target_index,
  axis,
  past_operation,
  setTarget_table,
  setTarget_index,
  setPast_operation,
  markCheckBoxUncheck,
  setOperationList,
  setUpdateTables,
}) => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const totalColumns = target_table[currentTableId].table[0].length;
  const handleSubmit = async (e) => {
    e.preventDefault();
    let targetColumn = [...Array(totalColumns).keys()];
    const payload = {
      target_table: target_table[currentTableId].table,
      user_operation: {
        target_index:targetColumn,
        axis:1,
        operation: "HEADERS",
        delimiter: ":",
        new_column_list: headers,
      },
      past_operation,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/extract/tableupdation",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Operation performed successfully!");
        let tableData = target_table;
        tableData[currentTableId].table = response.data.updated_table;
        setTarget_table(tableData);
        setTarget_index([]);
        setPast_operation([
          ...past_operation,
          response?.data?.operation_list[0],
        ]);
        markCheckBoxUncheck();
        setOperationList(response?.data.operation_list);
        setUpdateTables(response?.data.updated_table);
        setHeaders([]);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }

    setOpen(false);
  };
  const handleHeader = (e, index) => {
    const value = e.target.value;
    const arrValue = headers;
    arrValue[index] = value;
    setHeaders(arrValue);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid>
        <AuthContainer>
          <form onSubmit={handleSubmit}>
            <Grid textAlign={"center"} fontSize={"28px"}>
              <Grid mb={2} fontSize={"22px"}>
                ADD HEADERS
              </Grid>
              <Grid
                style={{
                  height: "200px",
                  overflowY: "auto",
                }}
              >
                {[...Array(totalColumns).keys()].map((colName, index) => (
                  <Grid
                    display="flex"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Grid fontSize={"16px"} mr={2}>
                      Column {String.fromCharCode(65 + index)} :
                    </Grid>
                    <Grid>
                      <TextField
                        id="outlined-name"
                        placeholder="Enter New Column Name"
                        variant="standard"
                        fontSize="16px"
                        name={colName}
                        onChange={(e) => handleHeader(e, index)}
                        required
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              mt={2}
            >
              <TableModalButton variant="contained" size="small" type="submit">
                Save
              </TableModalButton>
              <TableModalButton
                variant="contained"
                size="small"
                onClick={() => setOpen(false)}
              >
                Cancel
              </TableModalButton>
            </Grid>
          </form>
        </AuthContainer>
      </Grid>
    </Modal>
  );
};

const RenameColumnModal = ({
  open,
  setOpen,
  renameTarNames,
  setRenameTarNames,
  target_table,
  currentTableId,
  target_index,
  axis,
  past_operation,
  setTarget_table,
  setTarget_index,
  setPast_operation,
  markCheckBoxUncheck,
  setOperationList,
  setUpdateTables,
}) => {
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newNames = renameTarNames.map((item) => e.target[item].value);
    const payload = {
      target_table: target_table[currentTableId].table,
      user_operation: {
        operation: "RENAME",
        target_index,
        axis,
        new_column_list: newNames,
      },
      past_operation,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/extract/tableupdation",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Operation performed successfully!");
        let tableData = target_table;
        tableData[currentTableId].table = response.data.updated_table;
        setTarget_table(tableData);
        setTarget_index([]);
        setPast_operation([
          ...past_operation,
          response?.data?.operation_list[0],
        ]);
        markCheckBoxUncheck();
        setOperationList(response?.data.operation_list);
        setUpdateTables(response?.data.updated_table);
        setRenameTarNames([]);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }

    setOpen(false);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid>
        <AuthContainer>
          <form onSubmit={handleSubmit}>
            <Grid textAlign={"center"} fontSize={"28px"}>
              <Grid mb={2} fontSize={"22px"}>
                RENAME COLUMN
              </Grid>
              {renameTarNames.map((colName, index) => (
                <Grid
                  display="flex"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Grid fontSize={"16px"} mr={2}>
                    {colName} :
                  </Grid>
                  <Grid>
                    <TextField
                      id="outlined-name"
                      placeholder="Enter New Column Name"
                      variant="standard"
                      fontSize="16px"
                      name={colName}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              mt={2}
            >
              <TableModalButton variant="contained" size="small" type="submit">
                Rename
              </TableModalButton>
              <TableModalButton
                variant="contained"
                size="small"
                onClick={() => setOpen(false)}
              >
                Cancel
              </TableModalButton>
            </Grid>
          </form>
        </AuthContainer>
      </Grid>
    </Modal>
  );
};

const SaveWorkflowModal = ({ open, setOpen, past_operation }) => {
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        workflow_name: e.target.workflow.value,
        workflow: past_operation,
      };
      let response = await axios.post(
        BASE_URL + "/extract/saveworkflow",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.success);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
    setOpen(false);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Grid>
        <AuthContainer>
          <form onSubmit={handleSubmit}>
            <Grid textAlign={"center"} fontSize={"28px"}>
              <Grid mb={2} fontSize={"22px"}>
                SAVE WORKFLOW
              </Grid>
              <Grid
                display="flex"
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Grid fontSize={"16px"} mr={2}>
                  Workflow Name :
                </Grid>
                <Grid>
                  <TextField
                    id="outlined-name"
                    placeholder="Enter New Workflow Name"
                    variant="standard"
                    fontSize="16px"
                    name="workflow"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              mt={2}
            >
              <TableModalButton variant="contained" size="small" type="submit">
                Save
              </TableModalButton>
              <TableModalButton
                variant="contained"
                size="small"
                onClick={() => setOpen(false)}
              >
                Cancel
              </TableModalButton>
            </Grid>
          </form>
        </AuthContainer>
      </Grid>
    </Modal>
  );
};
