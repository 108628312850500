import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const DataTable = ({ props }) => {
  let rows = [];
  let cols = [];

  props?.forEach((eachRow, index) => {
    index === 0 ? cols.push(eachRow) : rows.push(eachRow);
  });

  return (
    <>
      {/* <Button onClick={handleClick}>Edit Table</Button> */}
      <TableContainer component={Paper} sx={{height:'inherit'}}>
        <Table
          stickyHeader={true}
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            {cols.map((col, index) => (
              <TableRow>
                {col.map((cell) => (
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      color: "#ffffff",
                      background: "green",
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow>
                {row.map((cell) => (
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DataTable;
