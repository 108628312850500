import React, { useState } from "react";
import { Grid } from "@mui/material";
import { CustomKeyModalButton, ModalContainer } from "../CreateTemplate/Style";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../Utils";
export default function SaveTemplateDialog({ open, setOpen, templateID }) {
  const [data, setData] = useState({
    template_id: templateID,
    template_name: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    e.preventDefault();
    let field = e.target.name;
    let value = e.target.value;
    setData({ ...data, [field]: value });
  };
  const handleSaveTemplate = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/save-template",
        data,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setOpen(false);
        navigate("/");
        // setWorkflow(response.data);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  return (
    <>
      {open && (
        <Grid
          position="fixed"
          zIndex={1300}
          top="0"
          left="0"
          bottom="0"
          right="0"
          borderRadius={"10px"}
        >
          <Grid
            position="fixed"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            zIndex={"-1"}
            top="0"
            right="0"
            bottom="0"
            left="0"
            bgcolor={"rgba(0, 0, 0, 0.5)"}
          ></Grid>
          <Grid
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            height="100%"
          >
            <ModalContainer>
              <Grid
                p={1}
                bgcolor={"#003AA6"}
                color="#fff"
                textAlign={"center"}
                fontSize="22px"
              >
                SAVE TEMPLATE
              </Grid>
              <Grid px={4} pb={3} pt={2}>
                <Grid fontWeight={"700"} mb={2} textAlign="center">
                  Enter Template Name
                </Grid>
                <input
                  placeholder="Enter Template Name"
                  name="template_name"
                  onChange={handleChange}
                  style={{
                    border: "none",
                    outline: "none",
                    borderBottom: "1px solid black",
                    width: "100%",
                    textAlign: "center",
                  }}
                />
                <Grid display="flex" mt={3} justifyContent="center">
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      //   handleCreateKeyFunc(createKeyModalData);
                      //   setCreateKeyModalOpen(true);
                      e.preventDefault();
                      handleSaveTemplate();
                    }}
                  >
                    Save
                  </CustomKeyModalButton>
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                      setData(null);
                    }}
                  >
                    Cancel
                  </CustomKeyModalButton>
                </Grid>
              </Grid>
            </ModalContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
}
