import axios from "axios";
import { BASE_URL } from "../../../Utils";

export const getAllTemplatesApi = async () => {
  return await axios.get(BASE_URL + "/template-extraction/get-all-templates", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};

export const removeTemplateApi = async ({ template_id }) => {
  return await axios.post(
    BASE_URL + "/template-extraction/delete-template",
    { template_id },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};

export const getTemplateApi = async ({ template_id }) => {
  return await axios.post(
    BASE_URL + "/template-extraction/get-template",
    { template_id },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};

export const startTemplateApi = async ({ formData }) => {
  return await axios.post(
    BASE_URL + "/template-service/start-template",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};

export const saveSelectedTable = async ({ payload }) => {
  return await axios.post(
    BASE_URL + "/template-extraction/save-selected-table",
    payload,
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};
