import React, { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import Templates from "./Templates";
import {
  BackBtn,
  InnerContainer,
  RecordContainer,
  RefreshBtn,
  SidebarContainer,
  SidebarTab,
  StyledButton,
  StyledContainer,
} from "./Style";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CREATE_SIDEBAR_TABS, DASHBOARD_FILTERS } from "./Util";
import { INVALID_TOKEN_MESSAGE, REMOVE_TEMP_SUCCESS_MESSAGE } from "./Constant";
import { getAllTemplatesApi, removeTemplateApi } from "./Service";
import { CreateTemplateModal } from "./CreateTemplateModal";

const Sidebar = () => {
  const [curTab, setCurTab] = useState("ALL");
  const [createOpen, setCreateOpen] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [allTemplatesData, setAllTemplatesData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const fetchTemplateList = async () => {
    setLoading(true);
    try {
      let response = await getAllTemplatesApi();
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        setAllTemplatesData(response.data.template);
        if (curTab === "ALL") {
          setTemplateData(response.data.template);
        } else {
          const newData = response.data.template.filter(
            (data) => data.template_status === curTab
          );
          setTemplateData(newData);
        }
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (curTab === "ALL") {
      fetchTemplateList();
    } else {
      const newData = allTemplatesData.filter(
        (data) => data.template_status === curTab
      );
      setTemplateData(newData);
    }
  }, [curTab]);

  const handleRemoveTemplate = async (e) => {
    e.preventDefault();
    let template_id = e.target.name;
    try {
      setLoading(true);
      let response = await removeTemplateApi({ template_id });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
          setLoading(false);
        }
      } else {
        const newData = templateData.filter((data) => data.id != template_id);
        setTemplateData(newData);
        setTimeout(() => {
          setLoading(false);
          toast.success(REMOVE_TEMP_SUCCESS_MESSAGE);
        }, 1000);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <InnerContainer>
        <SidebarContainer>
          {CREATE_SIDEBAR_TABS.map((tab) => (
            <SidebarTab
              bgcolor={curTab === tab && "rgb(255,255,255,0.5)"}
              onClick={() => setCurTab(tab)}
            >
              {DASHBOARD_FILTERS[tab]}
            </SidebarTab>
          ))}
        </SidebarContainer>
      </InnerContainer>
      <Grid flex={1}>
        <Grid my={7}>
          <StyledButton onClick={() => setCreateOpen(true)}>
            CREATE NEW TEMPLATE
          </StyledButton>
          <RefreshBtn onClick={() => fetchTemplateList()}>Refresh</RefreshBtn>
          <BackBtn onClick={() => navigate("/")}>Back</BackBtn>
        </Grid>
        <Grid display="flex" justifyContent={"center"}>
          {loading ? (
            <RecordContainer>
              <CircularProgress size={25} />
            </RecordContainer>
          ) : templateData?.length === 0 ? (
            <RecordContainer>No records found.</RecordContainer>
          ) : (
            <Templates
              templateData={templateData}
              handleRemoveTemplate={handleRemoveTemplate}
            />
          )}
        </Grid>
      </Grid>

      {createOpen && (
        <CreateTemplateModal
          open={createOpen}
          handleClose={() => setCreateOpen(false)}
          fetchTemplateList={fetchTemplateList}
        />
      )}
    </StyledContainer>
  );
};

export default Sidebar;
