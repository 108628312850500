import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";
import EDIT_LOGO from "../../../Assets/Icons/edit.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import { ExtractorButton, ExtractorButtonImage } from "./Style";
import { CSVLink } from "react-csv";
import EditTableText from "../ExtractPdf/EditTableText.jsx";
import { ServiceButton } from "./Style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  mt: 3,
  width: "1200px",
  height: "500px",
};

const ApplyTemplate = () => {
  const location = useLocation();
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [currentTableId, setCurrentTableId] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [editText, setEditText] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(
    location.state.data.filter((item) => item.save_to_location === "False")
      .length === location.state.data.length
  );
  let tableId =
    location?.state?.data[currentRecordId]?.final_tables[currentTableId]
      ?.table_id;
  let [data, setData] = useState([]);
  const navigate = useNavigate();
  const [cols, setCols] = useState([
    [
      "PDF Name",
      "Applied Template Name",
      "Matched %",
      "Location to save",
      "View table",
    ],
  ]);
  let arr = [];

  useEffect(() => {
    if (location.state.input_type === "FILE") {
      viewTableHandle(0);
    }
  }, []);

  const [rows, setRows] = useState(
    location.state.data.map((item) => {
      arr.push(item.pdf_name);
      arr.push(item.template_name);
      arr.push(item.matched_percentage);
      arr.push(item.saving_location);
      let dummy = arr;
      arr = [];
      return dummy;
    })
  );

  const viewTableHandle = (index) => {
    setShowTable(true);
    setCurrentRecordId(index);
    const tableArr = location?.state?.data[index]?.final_tables[0]?.table;
    let localCols = [];
    let localRows = [];
    tableArr?.forEach((eachRow, index) => {
      index === 0 ? localCols.push(eachRow) : localRows.push(eachRow);
    });
    setRows(localRows);
    setCols(localCols);
  };

  useEffect(() => {
    if (Number.isInteger(currentRecordId)) {
      const tableArr =
        location?.state?.data[currentRecordId]?.final_tables[currentTableId]
          .table;
      let localCols = [];
      let localRows = [];
      tableArr?.forEach((eachRow, index) => {
        index === 0 ? localCols.push(eachRow) : localRows.push(eachRow);
      });
      setRows(localRows);
      setCols(localCols);
    }
  }, [currentTableId]);

  const backHandler = () => {
    if (location.state.input_type === "FILE") {
      navigate("/dashboard/apply");
    }
    setCols([
      [
        "PDF Name",
        "Applied Template Name",
        "Matched %",
        "Location to save",
        "view table",
      ],
    ]);
    setRows(
      location.state.data.map((item) => {
        arr.push(item.pdf_name);
        arr.push(item.template_name);
        arr.push(item.matched_percentage);
        arr.push(item.saving_location);
        let dummy = arr;
        arr = [];
        return dummy;
      })
    );
    setCurrentTableId(0);
    setCurrentRecordId(null);
    setShowTable(false);
  };

  const submitData = async () => {
    const payload = {
      pdfs_final_output: location.state.data,
    };
    try {
      //   setLoading(true);
      let response = await axios.post(
        BASE_URL + "/storage-connection/submit-data-of-multiple-pdf",
        payload,
        {
          headers: {
            Accept: "application/json",
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Submitted the data successfully.");
        navigate("/");
      }
      //   setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  return (
    <>
      {!editText && (
        <Box sx={style}>
          {showTable ? (
            <>
              <Grid
                display={"flex"}
                justifyContent="end"
                alignItems={"center"}
                mb={2}
              >
                <Grid
                  textAlign={"center"}
                  fontSize="20px"
                  fontWeight={"600"}
                  width="90%"
                >
                  Table Result
                </Grid>
                {showTable && (
                  <Grid onClick={backHandler} sx={{ cursor: "pointer" }}>
                    <ArrowBackIosNewIcon /> Back
                  </Grid>
                )}
              </Grid>
              <Grid display="flex" height="350px">
                <Grid
                  display="flex"
                  alignItems={"center"}
                  height="fit-content"
                  justifyContent="space-between"
                  width="120px"
                >
                  <Grid
                    bgcolor="#003AA6"
                    pt={4}
                    pb={2}
                    width="90%"
                    display="flex"
                    flexDirection={"column"}
                    borderRadius={"16px 16px 16px 16px"}
                    alignItems="center"
                    height="350px"
                    sx={{ overflowY: "auto" }}
                  >
                    <Grid fontSize={"18px"} color="#ffffff" mb={3}>
                      Tables
                    </Grid>
                    {location.state.data[currentRecordId]?.final_tables?.map(
                      (table, index) => (
                        <Grid my={2.5} key={table.table_id}>
                          <Grid onClick={() => setCurrentTableId(index)}>
                            <img
                              src={TABLE_SELECT_LOGO}
                              alt="#"
                              width={"70"}
                              height="70"
                              style={{
                                display: "block",
                                margin: "0 auto",
                                cursor: "pointer",
                              }}
                            />
                          </Grid>
                          <Grid
                            display="flex"
                            justifyContent={"center"}
                            pt={1}
                            onClick={() => setCurrentTableId(index)}
                          >
                            <Grid
                              color="#ffffff"
                              fontSize={"14px"}
                              textAlign="center"
                              ml={1}
                            >
                              Table {index + 1}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ height: "360px" }}>
                  <Table
                    stickyHeader={true}
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      {cols?.map((col, index) => (
                        <TableRow>
                          {col?.map((cell) => (
                            <TableCell
                              align="center"
                              sx={{
                                border: "1px solid rgba(224, 224, 224, 1)",
                                color: "#ffffff",
                                background: "green",
                              }}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableHead>
                    <TableBody>
                      {rows?.map((row, index) => (
                        <TableRow>
                          {row?.map((cell) => (
                            <TableCell
                              align="center"
                              sx={{
                                border: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid mt={3} marginLeft={"40%"} mb={3}>
                <CSVLink
                  data={
                    location?.state?.data[currentRecordId]?.final_tables[
                      currentTableId
                    ]?.table || ""
                  }
                  style={{ textDecoration: "none" }}
                >
                  <ExtractorButton variant="contained">
                    <ExtractorButtonImage
                      src={DOWNLOAD_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    Download
                  </ExtractorButton>
                </CSVLink>
                <ExtractorButton
                  variant="contained"
                  onClick={() => setEditText(true)}
                >
                  <ExtractorButtonImage
                    src={EDIT_LOGO}
                    alt="#"
                    width={"12"}
                    height="12"
                  />
                  Edit Table Text
                </ExtractorButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid>
                <Grid
                  textAlign={"center"}
                  mb={2}
                  fontSize="20px"
                  fontWeight={"600"}
                  width="90%"
                >
                  Table Result
                </Grid>
              </Grid>
              <TableContainer component={Paper} sx={{ height: "360px" }}>
                <Table
                  stickyHeader={true}
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    {cols?.map((col, index) => (
                      <TableRow>
                        {col?.map((cell) => (
                          <TableCell
                            align="center"
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              color: "#ffffff",
                              background: "green",
                            }}
                          >
                            {cell}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => (
                      <TableRow>
                        {row?.map((cell, i) => (
                          <TableCell
                            align="center"
                            sx={{
                              border: "1px solid rgba(224, 224, 224, 1)",
                              background:
                                location.state.data[index].save_to_location ===
                                "False"
                                  ? "#c9c6c6"
                                  : "#fff",
                            }}
                          >
                            {cell}
                          </TableCell>
                        ))}
                        <TableCell
                          align="center"
                          sx={{
                            border: "1px solid rgba(224, 224, 224, 1)",
                            cursor:
                              location.state.data[index].save_to_location ===
                              "False"
                                ? "auto"
                                : "pointer",
                            background:
                              location.state.data[index].save_to_location ===
                              "False"
                                ? "#c9c6c6"
                                : "#fff",
                          }}
                          onClick={() =>
                            location.state.data[index].save_to_location ===
                            "False"
                              ? null
                              : viewTableHandle(index)
                          }
                        >
                          <VisibilityIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid mt={3} marginLeft={"40%"} mb={3}>
                {location.state.input_type === "FILE" ? (
                  <ServiceButton onClick={submitData}>Download</ServiceButton>
                ) : (
                  <ServiceButton
                    background={isSubmitDisable ? "#c9c6c6" : "#003aa6"}
                    onClick={submitData}
                    disabled={isSubmitDisable}
                  >
                    Submit
                  </ServiceButton>
                )}
              </Grid>
            </>
          )}
        </Box>
      )}
      {editText && (
        <EditTableText
          colsIn={cols}
          rowsIn={rows}
          final_table_id={location?.state?.data[currentRecordId]?.id}
          table_id={tableId}
        />
      )}
    </>
  );
};

export default ApplyTemplate;
