import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
import BACK_ARROW_LOGO from "../../../Assets/Icons/back-arrow.svg";
import { ExtractorButton, ExtractorButtonImage } from "../Dashboard/Style";
import DataTable from "./DataTable";
import { CustomKeyButton, CustomKeyModalButton, ModalContainer } from "./Style";
import axios from "axios";
import { toast } from "react-hot-toast";
import { PDFViewer } from "./PDFViewer";
import { BASE_URL } from "../../../Utils";
import { create } from "yup/lib/Reference";
import { SelectTableView } from "./SelectTableView";
import { ExtractedKeyView } from "./ExtractedKeyView";
import { CustomKeyView } from "./CustomKeyView";
import { get } from "lodash";
import { isEmpty } from "lodash";

const CreateTemplate = () => {
  let location = useLocation();
  // FLOW ==> BACK => 2, UPDATE => 0, selectedPairs => 2, selectedTables => 1, else 0
  const flow = get(location, "state.flow", "OTHER");
  const selectedPairsIds = get(location, "state.selectedPairsIds", []);
  const selectedTablesIds = get(location, "state.selectedTablesIds", []);
  const tableData = get(location, "state.extracted_tables", []);
  const template_id = get(location, "state.template_id", null);

  const pageMapper = {
    BACK: 2,
    UPDATE: 0,
    OTHER: null,
  };
  const [stepIndex, setStepIndex] = useState(
    pageMapper[flow] ||
      (!isEmpty(selectedPairsIds) ? 2 : !isEmpty(selectedTablesIds) ? 1 : 0)
  );
  const [selKeysArr, setSelKeysArr] = useState([]);
  const [pdfFile, setPdfFile] = useState(get(location, "state.pdf_file", ""));
  const pairFeatureList = get(location, "state.pair_feature_list", []);

  const getPairFeatureData = () => {
    let arr = [];
    pairFeatureList.forEach((pairArr) => {
      pairArr.forEach((item) => {
        arr.push({
          id: item.pair_id,
          key: item.target_key,
          value: item.target_value,
        });
      });
    });
    return arr;
  };

  const [extKeysArr, setExtKeysArr] = useState(getPairFeatureData());

  return stepIndex === 0 ? (
    <SelectTableView
      setStepIndex={setStepIndex}
      tableData={tableData}
      template_id={template_id}
    />
  ) : stepIndex === 1 ? (
    <ExtractedKeyView
      setStepIndex={setStepIndex}
      extKeysArr={extKeysArr}
      setExtKeysArr={setExtKeysArr}
      selKeysArr={selKeysArr}
      setSelKeysArr={setSelKeysArr}
      pairFeatureList={pairFeatureList}
      getPairFeatureData={getPairFeatureData}
      pdfFile={pdfFile}
    />
  ) : stepIndex === 2 ? (
    <CustomKeyView setStepIndex={setStepIndex} pdfFile={pdfFile} />
  ) : null;
};

export default CreateTemplate;
