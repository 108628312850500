import { Box, Button, Grid, styled } from "@mui/material";
import Styled from "styled-components";

export const FileInput = Styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
export const FileInputLabel = Styled.label`
  cursor: pointer;
  border-radius: 20px;
  border:${(props) =>
    props.s3Link || props.applyType === "multiple" || props.isUpdate
      ? "1px solid #d1cfcf"
      : "1px solid #003AA6"};
  line-height:34px;
  background: ${(props) =>
    props.s3Link || props.applyType === "multiple" || props.isUpdate
      ? "#d1cfcf"
      : "#003AA6"};
  color:#fff;
  margin-right:16px;
   width:90px;
   text-align:center;
`;
export const S3Input = Styled.input`
outline:none;
border-radius:20px;
color:#003AA6;
padding-left:10px;
border:${(props) =>
  props.file || props.isUpdate ? "1px solid #d1cfcf" : "1px solid #003AA6"};
width:100%;

line-height:34px;
background: ${(props) => (props.file || props.isUpdate ? "#d1cfcf" : "none")};
`;

export const ServiceButton = Styled.button`
display: block;
width: 270px;
line-height: 2;
background-color: ${(props) =>
  props.background ? props.background : "#003aa6"}; ;
color: #fff;
outline: none;
border-radius: 10px;
cursor: pointer;
border: none;
box-shadow: 0px 3px 6px #00000052;
user-select: none;
margin: 0 auto;
`;

export const ModalButton = Styled.button`
display: block;
  width: 150px;
  line-height: 2;
  font-size: 12px;
  background-color: #003aa6;
  color: #fff;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 3px 6px #00000052;
  user-select: none;
  margin: 0 auto;
`;

export const RefreshBtn = Styled.button`
position: absolute;
    right: 190px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const BackBtn = Styled.button`
position: absolute;
    right: 100px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const JobNameInput = Styled.input`
width: 100%;
border: 2px solid #003AA6;
border-radius: 6px;
padding: 4px;
outline: none;
`;
export const ModalInner = styled(Grid)({
  background: "#003AA6",
  color: "#fff",
  paddingTop: "6px",
  paddingBottom: "6px",
  textAlign: "center",
  borderRadius: "12px 12px 0 0",
});

export const StyledContainer = styled(Grid)({
  display: "flex",
  width: "100%",
  marginTop: "65px",
  overflow: "hidden",
  height: "89.5vh",
});

export const InnerContainer = styled(Grid)({
  minWidth: "18%",
  display: "flex",
  justifyContent: "space-between",
  overflow: "hidden",
});

export const SidebarContainer = styled(Grid)({
  width: "100%",
  height: "89.5vh",
  background: "#003AA6",
  paddingTop: "32px",
  paddingBottom: "16px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: "0 16px 16px 0",
});

export const SidebarTab = styled(Grid)({
  fontSize: "20px",
  color: "#ffffff",
  background: (props) => (props.bgcolor ? props.bgcolor : "auto"),
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  cursor: "pointer",
});

export const StyledButton = Styled.button`
    display: block;
    width: 270px;
    line-height: 2;
    background-color: #003aa6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
  `;

export const ActionButton = Styled.button`
  border: none;
  background: transparent;
  font-size: 16px;
  font-style: italic;
  color: #4393f0;
`;

export const Row = styled(Grid)({
  fontSize: "18px",
  width: "250px",
  marginRight: "8px",
});

export const RowFirst = styled(Grid)({
  fontSize: "18px",
  width: "600px",
  marginRight: "8px",
  wordBreak: "break-word",
});

export const RowContainer = styled(Grid)({
  display: "flex",
  width: "90%",
  marignTop: "8px",
  paddingBottom: "8px",
  borderBottom: "1px solid black",
  margin: "0 auto",
});

export const TableHead = styled(Grid)({
  fontSize: "24px",
  fontWeight: "600",
  display: "flex",
  width: "90%",
  margin: "0 auto",
});

export const ColCell = styled(Grid)({
  marginBottom: "16px",
  marginRight: "8px",
  width: (props) => (props.width ? props.width : "auto"),
});

export const RowSection = styled(Grid)({
  height: "300px",
  overflow: "auto",
});

export const RecordContainer = styled(Grid)({
  margin: "125px auto",
});

export const ModalContainer = styled(Grid)({
  fontSize: "22px",
  background: "#003AA6",
  paddingTop: "4px",
  paddingBottom: "4px",
  color: "#fff",
  borderRadius: "12px 12px 0 0",
  textAlign: "center",
});

export const StyledAddress = styled(Grid)({
  border: (props) => (props.file ? "1px solid #868282" : "1px solid #003AA6"),
  bgcolor: (props) => (props.file ? "d1cfcf" : "#003AA6"),
  color: (props) => (props.file ? "#000" : "#fff"),
  borderRadius: "20px",
  lineHeight: "36px",
  width: "120px",
  textAlign: "center",
  position: "absolute",
  right: "0px",
});

export const StyledInput = Styled.input`
width: 32px;
outline: none";
height: 38px;
`;

export const ModalStyle = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "12px",
  width: "700px",
});

export const ExtractorButton = styled(Button)`
  background: #003aa6;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: ${(props) => (props.width ? props.width : "180px")};
  margin: 0 10px;
`;

export const ExtractorButtonImage = Styled.img`
  margin-right:10px
  `;
