import React from "react";
import { StyledButton, StyledInput, StyledSpan } from "./Style";
import { CircularProgress, Grid } from "@mui/material";
import {
  AUTH_CURRENT_PAGE,
  INVALID_TOKEN_MESSAGE,
  LOGIN_SUCCESS_MESSAGE,
} from "./Constant";
import { toast } from "react-hot-toast";
import { loginSchema } from "./Util";
import { loginApi } from "./Service";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const Login = ({ setStepIndex, setCurrPage, setUserName }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  let email = window.location.search.substring(1).split("&&")[0] || "";
  let password = window.location.search.substring(1).split("&&")[1] || "";

  if (email && password) {
    email =
      CryptoJS.AES.decrypt(
        email,
        process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) || "";
    password =
      CryptoJS.AES.decrypt(
        password,
        process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8) || "";
  }

  function handleLogin(email, password) {
    let params = {
      email: email,
      password: password,
    };

    loginApi({ params })
      .then((res) => {
        sessionStorage.setItem("token", res.data.token);
        setUserName(res.data.name);
        sessionStorage.setItem("name", res.data.name);
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!email || !password) return;
    handleLogin(email, password);
  }, [email, password]);

  const dataHandler = (e) => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const loginHandler = async () => {
    let params = {
      email: formData.email,
      password: formData.password,
    };
    try {
      loginSchema
        .validate(params, { abortEarly: false })
        .then(async () => {
          try {
            setLoader(true);
            let response = await loginApi({ params });
            if (response.data.error) {
              let message = response.data.message;
              toast.error(message);
              if (message === INVALID_TOKEN_MESSAGE) {
                sessionStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              toast.success(LOGIN_SUCCESS_MESSAGE);
              sessionStorage.setItem("token", response.data.token);
              setUserName(response.data.name);
              sessionStorage.setItem("name", response.data.name);
              setStepIndex(2);
            }
            setLoader(false);
          } catch (error) {
            let message = error.response.data.error;
            toast.error(message);
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error(error.errors[0]);
        });
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  return (
    <Grid width='100%'>
      <Grid fontSize={"22px"} fontWeight='700'>
        Login into your account
      </Grid>
      <Grid my={2}>
        <StyledInput
          placeholder='Email Address'
          name='email'
          value={formData.email}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid>
        <StyledInput
          placeholder='Password'
          name='password'
          type='password'
          value={formData.password}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid>
        <Grid mt={2.5} mb={1.5}>
          {loader ? (
            <CircularProgress size={25} />
          ) : (
            <StyledButton onClick={loginHandler}>LOGIN</StyledButton>
          )}
        </Grid>
        <Grid fontSize={"14px"} fontWeight={"700"}>
          Not a user yet?{" "}
          <StyledSpan onClick={() => setCurrPage(AUTH_CURRENT_PAGE.SIGNUP)}>
            Sign up
          </StyledSpan>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
