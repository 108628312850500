import React from "react";
import { AUTH_CURRENT_PAGE } from "./Constant";
import Login from "./Login";
import Landing from "./Landing";
import SignUp from "./SignUp";

const Auth = ({ currPage, setCurrPage, setStepIndex, setUserName }) => {
  const authComponent = () =>
    currPage === AUTH_CURRENT_PAGE.LOGIN ? (
      <Login
        setCurrPage={setCurrPage}
        setStepIndex={setStepIndex}
        setUserName={setUserName}
      />
    ) : currPage === AUTH_CURRENT_PAGE.LANDING ? (
      <Landing setCurrPage={setCurrPage} />
    ) : (
      <SignUp setCurrPage={setCurrPage} />
    );

  return <>{authComponent()}</>;
};

export default Auth;
