export const SIGNUP_SUCCESS_MESSAGE =
  "OTP has been sent to your registered email address.";
export const LOGIN_SUCCESS_MESSAGE = "User loggedin successfully";
export const OTP_SUCCESS_MESSAGE = "user signup successfully.";
export const RESEND_OTP_SUCCESS_MESSAGE =
  "OTP send to your email address again.";
export const INVALID_TOKEN_MESSAGE = "Invalid Access Token.. Please login";
export const AUTH_CURRENT_PAGE = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  LANDING: "LANDING"
};
