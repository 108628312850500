import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Label, Input } from "./Style";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useNavigate } from "react-router";
import { toast } from "react-hot-toast";
import { INVALID_TOKEN_MESSAGE } from "../CreateTemplate/Constant";
import { getUser } from "./Service";
import { StyledBack } from "./Style";
import { get } from "lodash";

const Profile = () => {
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  const fetchUserData = async () => {
    try {
      let response = await getUser();
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        setUserData(response.data);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <Grid width="90%" mt={15} ml={10}>
      <Grid display="flex" mb={2}>
        <StyledBack onClick={() => navigate("/")}>
          <ArrowCircleLeftIcon />
        </StyledBack>
        <Grid mr={2}>
          <Label>First Name</Label>
          <Input placeholder={get(userData, "first_name", "")} disabled />
        </Grid>
        <Grid>
          <Label>Last Name</Label>
          <Input placeholder={get(userData, "last_name", "")} disabled />
        </Grid>
      </Grid>
      <Grid mb={2}>
        <Label>Email</Label>
        <Input
          placeholder={get(userData, "email", "")}
          disabled
          width="330px"
        />
      </Grid>
    </Grid>
  );
};

export default Profile;
