import * as React from "react";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import { saveAs } from "file-saver";
import { ExtractorButton, ExtractorButtonImage } from "./Style";
import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";
import JSZip from "jszip";

export default function ImageModal(props) {
  const [selectedArr, setSelectedArr] = React.useState([]);
  let bin = Array.from(props.props);

  const handleDownloadSelected = () => {
    bin.forEach((eachImage) => {
      if (selectedArr.includes(eachImage.key))
        saveAs(`data:image/jpeg;base64,${eachImage.image}`, "image.jpg");
    });
  };

  const handleDownloadAll = () => {
    let data = [];
    bin.forEach((eachImage) => {
      data.push(`data:image/png;base64,${eachImage.image}`);
    });
    const jszip = new JSZip();
    data.forEach((item, index) => {
      var binary = atob(item.split(",")[1]);
      var array = [];
      for (let j = 0; j < binary.length; j++) {
        array.push(binary.charCodeAt(j));
      }
      let image = new Blob([new Uint8Array(array)], {
        type: "image/png",
      });
      jszip.file(`image_${index}.png`, image);
      if (index === data.length - 1) {
        jszip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "extracted_images.zip");
        });
      }
    });
  };

  return (
    <Grid p={3} pr={1}>
      <Grid
        height="400px"
        sx={{ overflowY: "auto" }}
        pr={1}
        pb={1}
      >
        <ImageList
          sx={{ width: "auto", height: "auto" }}
          cols={4}
          gap={20}
          rowHeight={164}
        >
          {bin.map((eachImage) => (
            <ImageListItem
              key={eachImage.key}
              sx={
                selectedArr.includes(eachImage.key)
                  ? {
                      cursor: "pointer",
                      border: "3px solid #0008FF",
                      borderRadius: "4px",
                      ".MuiImageListItem-img": {
                        objectFit: "contain",
                      },
                    }
                  : {
                      cursor: "pointer",
                      border: "3px solid #f5f5f2",
                      borderRadius: "4px",
                      ".MuiImageListItem-img": {
                        objectFit: "contain",
                      },
                    }
              }
              onClick={
                selectedArr.includes(eachImage.key)
                  ? () =>
                      setSelectedArr(
                        selectedArr.filter((e) => e !== eachImage.key)
                      )
                  : () => setSelectedArr([...selectedArr, eachImage.key])
              }
            >
              <img
                src={`data:image/jpeg; base64, ${eachImage.image}`}
                alt="test"
                style={{ height: "-webkit-fill-available" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      <Grid position={"relative"}>
        <Grid
          position="absolute"
          top="50px"
          left="30%"
          display="flex"
          justifyContent={"center"}
        >
          <ExtractorButton
            variant="contained"
            onClick={handleDownloadSelected}
            disabled={selectedArr.length === 0}
            width="280px"
          >
            <ExtractorButtonImage
              src={DOWNLOAD_LOGO}
              alt="#"
              width={"12"}
              height="12"
            />
            Download Selected
          </ExtractorButton>
          <ExtractorButton
            variant="contained"
            onClick={() => handleDownloadAll()}
            width="280px"
          >
            <ExtractorButtonImage
              src={DOWNLOAD_LOGO}
              alt="#"
              width={"12"}
              height="12"
            />
            Download All (Zip)
          </ExtractorButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
