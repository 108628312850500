import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
import BACK_ARROW_LOGO from "../../../Assets/Icons/back-arrow.svg";
import { ExtractorButton, ExtractorButtonImage } from "./Style";
import DataTable from "./DataTable";
import {
  CreateFlowButton,
  CustomKeyButton,
  CustomKeyContainer,
  CustomKeyModalButton,
  ModalContainer,
  NavsDiv,
  PdfNavs,
  PdfViewContainer,
  SidebarContainer,
} from "./Style";
import axios from "axios";
import { toast } from "react-hot-toast";
import { PDFViewer } from "./PDFViewer";
import { BASE_URL } from "../../../Utils";
import { create } from "yup/lib/Reference";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CustomKeyView = ({ setStepIndex, pdfFile }) => {
  const [isCoordSel] = useState(true);
  let location = useLocation();
  const navigate = useNavigate();
  const templateID = location.state.template_id;
  const [createKeyModalOpen, setCreateKeyModalOpen] = useState(false);
  const [createKeyModalData, setCreateKeyModalData] = useState("");
  const [customKeysPair, setCustomKeysPair] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [bboxResponse, setBboxresponse] = useState("");
  const [selectedKey, setSelectedKey] = useState({});
  const [allSeperator, setAllSeperator] = useState(null);
  const [allSeperatorNames, setAllSeperatorNames] = useState([]);
  const [seperatorName, setSeperatorName] = useState([]);
  const [allSeperatorKeys, setAllSeperatorKeys] = useState(null);
  const [selectedKVPair, setSelectedKVPair] = useState({});
  const [splitter, setSplitter] = useState(null);
  const [removeCustomKeyModal, setRemoveCustomKeyModal] = useState(false);
  const drawingRef = useRef();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSeperatorName(typeof value === "string" ? value.split(",") : value);
    let obj = {};
    for (const key in allSeperator) {
      if (value.includes(key)) {
        obj[key] = allSeperator[key];
      }
    }
    console.log(obj);
    setAllSeperatorKeys({ ...allSeperatorKeys, ...obj });
  };

  const getAllCustomArea = () => {
    let payload = {
      template_id: templateID,
    };
    axios
      .post(BASE_URL + "/template-extraction/get-all-custom-area", payload, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          let message = res.data.error;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          toast.success(res.data.message);
          let data = res.data.custom_areas;
          let keys = [];
          // eslint-disable-next-line array-callback-return
          data.map((one) => {
            keys.push({
              area_id: one.area_id,
              key: Object.keys(one.pair_generated)[0],
              value: one.pair_generated[Object.keys(one.pair_generated)[0]],
              selected: false,
            });
          });
          setCustomKeysPair(keys);
        }
      })
      .catch((error) => {
        let message = error.response.data.error;
        toast.error(message);
      });
  };

  const handleCreateKeyFunc = async (createKeyModalData) => {
    const user = sessionStorage.getItem("token");
    let data = {
      template_id: templateID,
      function_type: splitter,
      key_name: createKeyModalData.key_name,
      page_no: coordinates[coordinates.length - 1].pageNumber,
      custom_coordinates: coordinates[coordinates.length - 1].customCoordinates,
      bbox_list: bboxResponse.bounding_box_list,
      key_value_pair: selectedKVPair,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/custom-selection",
        data,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setCreateKeyModalOpen(false);
        getAllCustomArea();
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleGetSelectedPair = async () => {
    const user = sessionStorage.getItem("token");
    let payload = {
      template_id: templateID,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/get-selected-pair",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        // setCreateKeyModalData("")
        // navigate("/components", {
        //   state: { finalTables: response.data, templateID: templateID },
        // });
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleSaveCustomPairs = async () => {
    const user = sessionStorage.getItem("token");
    let payload = {
      template_id: templateID,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/create-final-table",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setCreateKeyModalData("");
        navigate("/components", {
          state: { finalTables: response.data, templateID: templateID },
        });
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const handleCreateKeyData = (e) => {
    // let field = e.target.name;
    // let value = e.target.value;
    // setCreateKeyModalData({ ...createKeyModalData, [field]: value });
    // console.log(JSON.stringify(e.target.value));
    // setSelectedKVPair(e.target.value);
    let pairKey = e.target.value;
    let obj = {};
    Object.keys(allSeperatorKeys).forEach((sepKey) =>
      allSeperatorKeys[sepKey].forEach((pairObj) => {
        if (pairObj.pair.pairKey === pairKey) {
          obj[pairObj.pair.pairKey] = pairObj.pair.pairValue;
          setSplitter(sepKey + "_SPLIT");
        }
      })
    );
    console.log(obj);

    setSelectedKVPair(obj);
    setCreateKeyModalData({
      ...createKeyModalData,
    });
  };

  const sendBboxCoordinates = (data) => {
    const x0 = data[data.length - 1].customCoordinates.x0;
    const x1 = data[data.length - 1].customCoordinates.x1;
    const y0 = data[data.length - 1].customCoordinates.y0;
    const y1 = data[data.length - 1].customCoordinates.y1;
    if(x0 === x1 || y0 === y1)  return;
    let payload = {
      template_id: templateID,
      page_no: data[data.length - 1].pageNumber,
      custom_coordinates: data[data.length - 1].customCoordinates,
    };
    axios
      .post(BASE_URL + "/template-extraction/get-bbox-from-area", payload, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          let message = res.data.message;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          toast.success(res.data.message);
          setBboxresponse(res.data);
          setAllSeperator(res.data.key_value_pair);
          let seperators = res.data.key_value_pair;
          setAllSeperatorNames(Object.keys(seperators).map((name) => name));
          console.log(res.data.key_value_pair);
          setCreateKeyModalOpen(true);
        }
      })
      .catch((error) => {
        let message = error.response.data.error;
        toast.error(message);
      });
  };

  const removeSelectedPair = (area_id) => {
    let payload = {
      area_id,
    };
    axios
      .post(BASE_URL + "/template-extraction/delete-custom-area", payload, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.error) {
          let message = res.data.message;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          toast.success(res.data.message);
          getAllCustomArea();
        }
      })
      .catch((error) => {
        let message = error.response.data.error;
        toast.error(message);
      });
  };

  return (
    <Grid display={"flex"} width="100%" marginTop="65px">
      <Grid sx={{ minWidth: "35%" }}>
        <SidebarContainer>
          <Grid fontSize={"18px"} color="#ffffff" mb={1.2} mx="auto">
            Custom Keys
          </Grid>
          <CustomKeyContainer>
            {customKeysPair.map((pair) => (
              <Grid
                display="flex"
                borderBottom={"1px solid black"}
                sx={{ cursor: "pointer" }}
                style={
                  selectedKey.area_id === pair.area_id
                    ? { backgroundColor: "skyblue" }
                    : null
                }
                onClick={() => {
                  setSelectedKey({
                    key: pair.key,
                    area_id: pair.area_id,
                  });
                }}
              >
                <Grid width="50%" p={0.4}>
                  {pair.key}
                </Grid>
                <Grid width="50%" p={0.4} borderLeft={"1px solid black"}>
                  {pair.value}
                </Grid>
              </Grid>
            ))}
          </CustomKeyContainer>
          <Grid display="flex" mt={3} mx="auto">
            <CustomKeyButton
              onClick={() => {
                setRemoveCustomKeyModal(true);
              }}
              variant="contained"
              size="small"
            >
              Remove Key
            </CustomKeyButton>
          </Grid>
        </SidebarContainer>
      </Grid>
      <Grid flex={1} background="#f9f9f9">
        <Grid p={3}>
          <Grid display={"flex"} justifyContent="center" mb={2}>
            <CreateFlowButton>TEMPLATE CREATION</CreateFlowButton>
          </Grid>
          <PdfViewContainer>
            <NavsDiv>
              <PdfNavs
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                Prev
              </PdfNavs>
              <PdfNavs
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                Next
              </PdfNavs>
            </NavsDiv>

            <PDFViewer
              page={"drag"}
              url={`data:application/pdf;base64,${pdfFile.split("'")[1]}`}
              isDrawing={isDrawing}
              setIsDrawing={setIsDrawing}
              startPosition={startPosition}
              setStartPosition={setStartPosition}
              endPosition={endPosition}
              setEndPosition={setEndPosition}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              numPages={numPages}
              setNumPages={setNumPages}
              sendBboxCoordinates={sendBboxCoordinates}
              ref={drawingRef}
            />
          </PdfViewContainer>
          <Grid
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            flexDirection="column"
          >
            <Grid display="flex" mt={1}>
              <ExtractorButton
                variant="contained"
                size="small"
                width="150px"
                onClick={() => setStepIndex(1)}
              >
                <ExtractorButtonImage
                  src={BACK_ARROW_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                Back
              </ExtractorButton>
              <ExtractorButton variant="contained" size="small">
                <ExtractorButtonImage
                  src={BACK_ARROW_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                Save Changes
              </ExtractorButton>
              <ExtractorButton
                variant="contained"
                size="small"
                onClick={handleSaveCustomPairs}
              >
                <ExtractorButtonImage
                  src={BACK_ARROW_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                Save & Continue
              </ExtractorButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {removeCustomKeyModal && (
        <Grid
          position="fixed"
          zIndex={1300}
          top="0"
          left="0"
          bottom="0"
          right="0"
          borderRadius={"10px"}
        >
          <Grid
            position="fixed"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            zIndex={"-1"}
            top="0"
            right="0"
            bottom="0"
            left="0"
            bgcolor={"rgba(0, 0, 0, 0.5)"}
          ></Grid>
          <Grid
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            height="100%"
          >
            <ModalContainer>
              <Grid
                p={1}
                bgcolor={"#003AA6"}
                color="#fff"
                textAlign={"center"}
                fontSize="22px"
              >
                REMOVE KEY
              </Grid>
              <Grid px={4} pb={3} pt={2}>
                <Grid fontWeight={"700"} textAlign="center" px={4} mb={1.5}>
                  Are You Sure? You Want to remove Key{" "}
                  <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
                    "{selectedKey.key}"
                  </span>
                </Grid>
                <Grid display="flex" mt={3} justifyContent="center">
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    disabled={!isCoordSel}
                    onClick={(e) => {
                      removeSelectedPair(selectedKey.area_id);
                      setRemoveCustomKeyModal(false);
                    }}
                  >
                    Remove
                  </CustomKeyModalButton>
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setRemoveCustomKeyModal(false);
                    }}
                  >
                    Cancel
                  </CustomKeyModalButton>
                </Grid>
              </Grid>
            </ModalContainer>
          </Grid>
        </Grid>
      )}

      {createKeyModalOpen && (
        <Grid
          position="fixed"
          zIndex={1300}
          top="0"
          left="0"
          bottom="0"
          right="0"
          borderRadius={"10px"}
        >
          <Grid
            position="fixed"
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            zIndex={"-1"}
            top="0"
            right="0"
            bottom="0"
            left="0"
            bgcolor={"rgba(0, 0, 0, 0.5)"}
          ></Grid>
          <Grid
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            height="100%"
          >
            <ModalContainer>
              <Grid
                p={1}
                bgcolor={"#003AA6"}
                color="#fff"
                textAlign={"center"}
                fontSize="22px"
              >
                CREATE CUSTOM KEY
              </Grid>
              <Grid px={4} pb={3} pt={2}>
                <Grid fontWeight={"700"} textAlign="center" px={4} mb={1.5}>
                  Do You Want To Create A Key For{" "}
                  <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
                    "{bboxResponse.text_in_bbox}"
                  </span>
                </Grid>
                <Grid fontWeight={"700"}>Choose Line Separator</Grid>
                <Grid mb={1.5}>
                  {/* <select
                    name="function_type"
                    onChange={handleCreateKeyData}
                    style={{ width: "100%" }}
                  >
                    <option value=" ">Select</option>
                    <option value="BLOCK_SPLIT">Block</option>
                    <option value="SPACE_SPLIT">Space</option>
                    <option value="COLON_SPLIT">Colon</option>
                    <option value="EQUAL_SPLIT">Equal</option>
                    <option value="CUSTOM_KEY">Custom</option>
                  </select> */}
                  {/* {allSeperator &&
                      Object.keys(allSeperator).map((seperator) => (
                        <Grid>
                          <input
                            type="checkbox"
                            id={seperator}
                            name={seperator}
                            value={seperator + "_SPLIT"}
                            onChange={(e) => handleSeperator(e)}
                          />
                          <label for={seperator}>{seperator}</label>
                        </Grid>
                      ))}
                      <div> */}
                  <FormControl
                    sx={{ width: "100%", outline: "none" }}
                    size="small"
                  >
                    <Select
                      multiple
                      value={seperatorName}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                    >
                      {allSeperatorNames.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Grid mt={2} fontWeight={700}>
                    Key Value Pair
                  </Grid>
                  <select
                    name="function_type"
                    onChange={handleCreateKeyData}
                    style={{ width: "100%", padding: "10px" }}
                  >
                    <option value=" ">Select</option>
                    {/* {Object.keys(allSeperatorKeys).forEach((sepKey) => (
                        {allSeperatorKeys[sepKey].map((pairObj) => <option value={pairObj.pair.pairKey}>{pairObj.pair.pairValue}</option>)}
                        
                      ))} */}
                    {allSeperatorKeys &&
                      Object.keys(allSeperatorKeys).map((sepKey) =>
                        allSeperatorKeys[sepKey].map((pairObj) => (
                          <optgroup
                            label={
                              "KEY : " + pairObj.pair.pairKey.substring(0, 20)
                            }
                          >
                            <option value={pairObj.pair.pairKey}>
                              {pairObj.pair.pairValue.substring(0, 20)}
                            </option>
                          </optgroup>
                        ))
                      )}
                  </select>
                </Grid>
                {createKeyModalData.function_type === "CUSTOM_KEY" && (
                  <>
                    <Grid fontWeight={"700"}>Custom Key Name</Grid>
                    <input
                      placeholder="Enter Key Name"
                      name="key_name"
                      onChange={handleCreateKeyData}
                      style={{
                        border: "none",
                        outline: "none",
                        borderBottom: "1px solid black",
                        width: "100%",
                      }}
                    />
                  </>
                )}
                <Grid display="flex" mt={3} justifyContent="center">
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    disabled={!isCoordSel}
                    onClick={(e) => {
                      handleCreateKeyFunc(createKeyModalData);
                      setCreateKeyModalOpen(true);
                      setCreateKeyModalData("");
                      drawingRef.current.style.width = "0px";
                      drawingRef.current.style.height = "0px";
                    }}
                  >
                    Save
                  </CustomKeyModalButton>
                  <CustomKeyModalButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setCreateKeyModalOpen(false);
                      setCreateKeyModalData("");
                      drawingRef.current.style.width = "0px";
                      drawingRef.current.style.height = "0px";
                    }}
                  >
                    Cancel
                  </CustomKeyModalButton>
                </Grid>
              </Grid>
            </ModalContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
