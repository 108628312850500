import { Grid } from "@mui/material";
import React from "react";
import WATERMARK_LOGO from "../../../Assets/Icons/watermark.svg";
import COMPRESSION_LOGO from "../../../Assets/Icons/compression.svg";
import NOINSTALL_LOGO from "../../../Assets/Icons/no-install.svg";
import PRIVACY_LOGO from "../../../Assets/Icons/insurance-mark.svg";
import FAST_LOGO from "../../../Assets/Icons/16-express.svg";
import COMPATIBLE_LOGO from "../../../Assets/Icons/compatible.svg";

const featuresList = [
    {
      icon: PRIVACY_LOGO,
      title: "User Privacy Standard",
      desciption:
        "We make sure to remove all files from our servers after 60 minutes of upload since your security and privacy are very important to us. Additionally, the SSL encryption on our platform ensures user security online.",
    },
    {
      icon: FAST_LOGO,
      title: "Fast PDF tool",
      desciption:
        "With a cloud-based system, all of our picture extraction processes happen instantly. Never again will you want to use another tool!",
    },
    {
      icon: COMPATIBLE_LOGO,
      title: "Universally Compatible",
      desciption:
        "Our browser-based HiPDF supports various platforms & devices, whether you use Mac, Windows, or Linux. It will work perfectly on every device.",
    },
    {
      icon: WATERMARK_LOGO,
      title: "No Watermark",
      desciption:
        "We never add watermarks to the files you download. Watermarks won't be present on any of the text, photos, or tables that were extracted.",
    },
    {
      icon: COMPRESSION_LOGO,
      title: "No Compression",
      desciption:
        "Extract photos from PDF exactly as they appear in your uploaded document. All the images found in the PDF are saved as separate files of the original format without any quality loss and as is.",
    },
    {
      icon: NOINSTALL_LOGO,
      title: "No Installation Required",
      desciption:
        "You do not need to download and install software. Images are extracted in the cloud on our servers. The app does not consume your systems resources.",
    },
  ];

const Feature = () => {
  return (
    <Grid
      display="flex"
      width={"100%"}
      bgcolor="#F9F9F9"
      pt={10}
      justifyContent="center"
      flexWrap={"wrap"}
    >
      {featuresList.map((feature) => (
        <Grid textAlign={"center"} width="28%" pb={10}>
          <Grid>
            <img src={feature.icon} alt="#" width={"60"} height="60" />
          </Grid>
          <Grid fontSize={"24px"} fontWeight="700" pb={1} pt={2}>
            {feature.title}
          </Grid>
          <Grid fontSize={"1em"} color="#757575" px={3}>
            {feature.desciption}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Feature;
