import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";
import {
  ResultModalButton,
  StyledDialogTitle,
  StyledDialogErrorDescription,
} from "../ExtractPdf/Style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewErrorPopUp({ open, setOpen, error }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        padding="10px"
      >
        <Grid margin="20px 0">
          <StyledDialogTitle>ERROR MESSAGE ...!</StyledDialogTitle>
          <StyledDialogErrorDescription>
            <p>{error}</p>
          </StyledDialogErrorDescription>
          <DialogActions>
            <Grid margin={"0 auto"}>
              <ResultModalButton variant="contained" onClick={handleClose}>
                Close
              </ResultModalButton>
            </Grid>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
}
