import React, { useState } from "react";
import { Grid } from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import {
  COLS,
  SIMPLIFY_DATE,
  SIMPLIFY_DATE_02,
  SIMPLIFY_PERIOD,
  SIMPLIFY_STATUS,
  SIMPLIFY_TIME,
  SIMPLIFY_TIME_02,
} from "./Util";
import ViewErrorPopUp from "../CreateTemplate/ViewErrorPopUp";
import {
  ActionButton,
  ColCell,
  Row,
  RowContainer,
  RowFirst,
  RowSection,
  TableHead,
} from "./Style";
import { INVALID_TOKEN_MESSAGE } from "./Constant";
import { getJobApi } from "./Service";

const Jobs = ({
  setIsUpdate,
  setIsEdit,
  jobsData,
  handleRemoveJob,
  setOpen,
  setEditJobId,
}) => {
  const navigate = useNavigate();
  const [viewError, setViewError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleViewJob = async (job_id) => {
    try {
      let response = await getJobApi({ job_id });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        navigate("/apply-template", {
          state: {
            data: response.data.jobs.jobresult,
            input_type: response.data.jobs.input_type,
          },
        });
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  return (
    <Grid width="90%">
      <TableHead>
        {COLS.map((col) => (
          <ColCell width={col.WIDTH}>{col.HEADING}</ColCell>
        ))}
      </TableHead>
      <RowSection>
        {jobsData?.map((job, index) => (
          <RowContainer name={job.job_id}>
            <RowFirst>{job.job_name}</RowFirst>

            <Row>
              {job.periodic_task
                ? SIMPLIFY_DATE(job.periodic_task.start_time)
                : SIMPLIFY_DATE_02(job.schedule_time)}
            </Row>
            <Row>
              {job.periodic_task
                ? SIMPLIFY_TIME(job.periodic_task.start_time)
                : SIMPLIFY_TIME_02(job.schedule_time)}
              {SIMPLIFY_PERIOD(job.execution_period)}
            </Row>
            <Row>{SIMPLIFY_STATUS(job.job_status)}</Row>
            <Row>
              <Grid fontSize={"16px"} fontStyle="italic" color="#4393f0">
                {job.job_status === "COMPLETED" &&
                  (job?.jobresult[0].matched_percentage <= 20 ? (
                    <div></div>
                  ) : (
                    <ActionButton
                      name={job.id}
                      onClick={() => handleViewJob(job.id, index)}
                    >
                      View
                    </ActionButton>
                  ))}
                {job.job_status === "ERROR" ? (
                  <ActionButton
                    name={job.job_id}
                    onClick={() => {
                      setViewError(true);
                      setErrorMessage(job.error_message);
                    }}
                  >
                    View Error
                  </ActionButton>
                ) : (
                  <ActionButton
                    name={job.job_id}
                    onClick={() => {
                      setIsUpdate(true);
                      setIsEdit(true);
                      setOpen(true);
                      setEditJobId(job.id);
                    }}
                  >
                    Update
                  </ActionButton>
                )}
                <ActionButton name={job.id} onClick={(e) => handleRemoveJob(e)}>
                  Remove
                </ActionButton>
              </Grid>
            </Row>
          </RowContainer>
        ))}
      </RowSection>
      <ViewErrorPopUp
        open={viewError}
        setOpen={setViewError}
        error={errorMessage}
      />
    </Grid>
  );
};

export default Jobs;
