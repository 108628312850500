import { CircularProgress, Grid } from "@mui/material";
import React from "react";
import { StyledButton, StyledInput, StyledSpan } from "./Style";
import {
  AUTH_CURRENT_PAGE,
  INVALID_TOKEN_MESSAGE,
  OTP_SUCCESS_MESSAGE,
  RESEND_OTP_SUCCESS_MESSAGE,
  SIGNUP_SUCCESS_MESSAGE,
} from "./Constant";
import { toast } from "react-hot-toast";
import { signupSchema } from "./Util";
import { signUpApi, otpVerificationApi, resendOtpApi } from "./Service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = ({ setCurrPage }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const awsToken = localStorage.getItem("AWS_SUBSCRIPTION_TOKEN")
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    conPassword: "",
    awsToken: awsToken,
  });
  const [otp, setOtp] = useState(null);
  const [otpWindow, setOtpWindow] = useState(false);

  const dataHandler = (e) => {
    let data = { ...formData };
    data[e.target.name] = e.target.value;
    setFormData(data);
  };

  const signupHandler = async () => {
    let params = {
      email: formData.email,
      first_name: formData.firstName,
      last_name: formData.lastName,
      password: formData.password,
      confirm_password: formData.conPassword,
      aws_token: formData.awsToken,
    };
    try {
      signupSchema
        .validate(formData, { abortEarly: false })
        .then(async () => {
          try {
            setLoader(true);
            let response = await signUpApi({ params });
            if (response.data.error) {
              let message = response.data.message;
              toast.error(message);
              if (message === INVALID_TOKEN_MESSAGE) {
                sessionStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              toast.success(response.data.message);
              toast.success(SIGNUP_SUCCESS_MESSAGE);
              setOtpWindow(true);
            }
            setLoader(false);
          } catch (error) {
            let messgae = error.response.data.error;
            toast.error(messgae);
            if (messgae === INVALID_TOKEN_MESSAGE) {
              sessionStorage.clear();
              navigate("/");
              window.location.reload();
            }
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error(error.errors[0]);
        });
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const resendOtpHandler = async () => {
    const params = {
      email: formData.email,
    };
    try {
      setLoader(true);
      let response = await resendOtpApi({ params });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(RESEND_OTP_SUCCESS_MESSAGE);
      }
      setLoader(false);
    } catch (error) {
      let messgae = error.response.data.error;
      toast.error(messgae);
      if (messgae === INVALID_TOKEN_MESSAGE) {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      }
      setLoader(false);
    }
  };

  const verifyOtpHandler = async () => {
    let params = {
      user_email: formData.email,
      user_otp: otp,
    };
    try {
      setLoader(true);
      let response = await otpVerificationApi({ params });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(OTP_SUCCESS_MESSAGE);
        setOtpWindow(false);
        setCurrPage(AUTH_CURRENT_PAGE.LANDING);
      }
      setLoader(false);
    } catch (error) {
      let messgae = error.response.data.error;
      toast.error(messgae);
      if (messgae === INVALID_TOKEN_MESSAGE) {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      }
      setLoader(false);
    }
  };

  return (
    <Grid width="100%">
      <Grid fontSize={"22px"} fontWeight="700">
        Create Your Account
      </Grid>
      <Grid mt={2}>
        <StyledInput
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          readOnly={otpWindow ? true : false}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid mt={2}>
        <StyledInput
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          readOnly={otpWindow ? true : false}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid mt={2}>
        <StyledInput
          placeholder="Email Address"
          name="email"
          value={formData.email}
          readOnly={otpWindow ? true : false}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid mt={2}>
        <StyledInput
          placeholder="Create Password"
          name="password"
          type="password"
          value={formData.password}
          readOnly={otpWindow ? true : false}
          onChange={(e) => dataHandler(e)}
        />
      </Grid>
      <Grid mt={2}>
        <StyledInput
          placeholder="Confirm Password"
          name="conPassword"
          type="password"
          value={formData.conPassword}
          onChange={(e) => dataHandler(e)}
          readOnly={otpWindow ? true : false}
        />
      </Grid>
      {otpWindow && (
        <Grid mt={2}>
          <StyledInput
            placeholder="OTP"
            name="otp"
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </Grid>
      )}
      <Grid>
        <Grid mt={2.5} mb={1.5}>
          {loader ? (
            <CircularProgress size={25} />
          ) : (
            <StyledButton
              onClick={otpWindow ? verifyOtpHandler : signupHandler}
            >
              {otpWindow ? "VERIFY" : "SIGN UP"}
            </StyledButton>
          )}
        </Grid>
        {otpWindow && (
          <Grid fontSize={"14px"} fontWeight={"700"}>
            <StyledSpan onClick={() => resendOtpHandler()}>
              Resend OTP
            </StyledSpan>
          </Grid>
        )}
        <Grid fontSize={"14px"} fontWeight={"700"}>
          Already a user?{" "}
          <StyledSpan
            onClick={() => {
              setCurrPage(AUTH_CURRENT_PAGE.LOGIN);
              setOtpWindow(false);
            }}
          >
            Login
          </StyledSpan>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUp;
