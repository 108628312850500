export const DASHBOARD_FILTERS = {
    'ALL' : 'All',
    'PENDING' : 'Pending',
    'IN_PROGRESS' : 'In-Progress',
    'COMPLETED' : 'Completed',
    'INITIATED' : 'Initiated',
    'ERROR' : 'Error',
}

export const EXTRACTED_PARTS = {
    IMAGE : "image",
    TEXT : "text",
    TABLE : "table"
}