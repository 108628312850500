import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import {
  FileInput,
  FileInputLabel,
  JobNameInput,
  ModalButton,
  ModalContainer,
  ModalInner,
  ModalStyle,
  S3Input,
  StyledAddress,
  StyledInput,
} from "./Style";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { APPLY_JOB_SUCCESS_MESSAGE, INVALID_TOKEN_MESSAGE } from "./Constant";
import { ApplyTemplateApi, getJobApi, updateJobApi } from "./Service";
import { applyTemplateSchema } from "./Util";

const ApplyModal = ({
  isUpdate,
  setIsUpdate,
  isEdit,
  setIsEdit,
  open,
  handleClose,
  fetchJobList,
  editJobId,
}) => {
  const [file, setFile] = useState(null);
  const [s3Link, setS3Link] = useState(null);
  const [saveAddress, setSaveAddress] = useState(null);
  const [jobName, setJobName] = useState("");
  const [applyType, setApplyType] = useState("single");
  const [scheduleTask, setScheduleTask] = useState("Scheduled");
  const [scheduleTaskTime, setScheduleTaskTime] = useState({
    hour: dayjs(new Date()).hour(),
    minute: dayjs(new Date()).minute(),
  });
  const [scheduleTaskDate, setScheduleTaskDate] = useState(dayjs(new Date()));
  const [exeType, setExeType] = useState("period");
  const [exePeriod, setExePeriod] = useState("ONCE");
  const [exeRandom, setExeRandom] = useState("SELECT DAYS");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getJobDetails = async (job_id) => {
    try {
      let response = await getJobApi({ job_id });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        setJobName(response.data.jobs.job_name);
        setScheduleTask(
          response.data.jobs.execute_type === "NOW" ? "now" : "Scheduled"
        );
        if (response.data.jobs.result_path) {
          setSaveAddress(response.data.jobs.result_path);
          setApplyType("multiple");
        }
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  useEffect(() => {
    if (isEdit) {
      getJobDetails(editJobId);
    }
  }, []);

  const fetchJobData = async (e) => {
    let formData = {};
    formData = {
      job_name: jobName,
      service_type: file ? "LOCAL_SYSTEM" : s3Link ? "S3" : "",
      input_type: applyType === "single" ? "FILE" : "FOLDER",
      execute_type: scheduleTask === "now" ? "NOW" : "SCHEDULED",
      execution_period: exePeriod,
      target_file: file ? file : s3Link,
      result_path: saveAddress,
      schedule_start_time:
        scheduleTask === "now"
          ? null
          : scheduleTaskDate.$D +
            "/" +
            (Number(scheduleTaskDate.$M) + 1) +
            "/" +
            scheduleTaskDate.$y +
            " " +
            scheduleTaskTime.hour +
            ":" +
            scheduleTaskTime.minute +
            ":00",
    };

    try {
      applyTemplateSchema
        .validate(formData, { abortEarly: false })
        .then(async () => {
          try {
            setLoading(true);
            let response = await ApplyTemplateApi({ formData });
            if (response.data.error) {
              let message = response.data.message;
              toast.error(message);
              if (message === INVALID_TOKEN_MESSAGE) {
                sessionStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              toast.success(APPLY_JOB_SUCCESS_MESSAGE);
              setIsEdit(false);
              setIsUpdate(false);
              fetchJobList();
              handleClose();
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
            let message = error.response.data.error;
            toast.error(message);
          }
        })
        .catch((error) => {
          toast.error(error.errors[0]);
        });
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  const editJobData = async (e) => {
    const formData = {
      job_id: editJobId,
      result_path: saveAddress,
      enable: false,
      schedule_start:
        scheduleTask === "now"
          ? null
          : scheduleTaskDate.$D +
            "/" +
            (Number(scheduleTaskDate.$M) + 1) +
            "/" +
            scheduleTaskDate.$y +
            " " +
            scheduleTaskTime.hour +
            ":" +
            scheduleTaskTime.minute +
            ":00",
    };
    try {
      setLoading(true);
      let response = await updateJobApi({ formData });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(APPLY_JOB_SUCCESS_MESSAGE);
        setIsEdit(false);
        setIsUpdate(false);
        fetchJobList();
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <ModalStyle>
        <ModalInner>
          {isUpdate ? "UPDATE TEMPLATE" : "APPLY TEMPLATE"}
        </ModalInner>
        <Grid p={4}>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Job Name
            </Grid>
            <JobNameInput
              placeholder="Job Name"
              value={jobName}
              name="jobName"
              disabled={isUpdate ? true : false}
              onChange={(e) => setJobName(e.target.value)}
            />
          </Grid>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Quantity
            </Grid>
            <Grid display="flex">
              <Grid display="flex" mr={6}>
                <input
                  type="radio"
                  id="single"
                  name="applyType"
                  checked={applyType === "single"}
                  disabled={isUpdate ? true : false}
                  onChange={() => setApplyType("single")}
                />
                <Grid ml={1}>
                  <label for="single">Single</label>
                </Grid>
              </Grid>
              <Grid display="flex">
                <input
                  type="radio"
                  id="multiple"
                  name="applyType"
                  checked={applyType === "multiple"}
                  disabled={isUpdate ? true : false}
                  onChange={() => setApplyType("multiple")}
                />
                <Grid ml={1}>
                  <label for="multiple">Multiple</label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid width="100%" mb={3}>
            <Grid fontWeight={"600"} mb={0.5}>
              Upload File/s
            </Grid>

            <Grid display="flex">
              <Grid>
                <FileInputLabel
                  s3Link={s3Link}
                  applyType={applyType}
                  // isEdit={isEdit}
                  isUpdate={isUpdate}
                  for="upload-photo"
                >
                  Local
                </FileInputLabel>
                <FileInput
                  type="file"
                  name="photo"
                  accept="application/pdf"
                  id="upload-photo"
                  disabled={s3Link || applyType === "multiple" || isUpdate}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>

              <Grid flex={1} position="relative" display="flex">
                <Grid width="100%">
                  <S3Input
                    type="text"
                    placeholder="S3 Link"
                    file={file}
                    // isEdit={isEdit}
                    isUpdate={isUpdate}
                    value={s3Link}
                    onChange={(e) => setS3Link(e.target.value)}
                    disabled={file || isUpdate}
                  />
                </Grid>
                <Grid
                  border={
                    file || isUpdate ? "1px solid #868282" : "1px solid #003AA6"
                  }
                  bgcolor={file || isUpdate ? "d1cfcf" : "#003AA6"}
                  color={file || isUpdate ? "#000" : "#fff"}
                  borderRadius={"20px"}
                  lineHeight="36px"
                  width="90px"
                  textAlign={"center"}
                  position={"absolute"}
                  right="0px"
                >
                  S3 Link
                </Grid>
                {file && (
                  <Grid
                    position="absolute"
                    top="45px"
                    left="-125px"
                    fontSize="10px"
                    color="green"
                    disabled={isUpdate ? "true" : "false"}
                  >
                    {file.name}
                  </Grid>
                )}
              </Grid>
            </Grid>

            {applyType === "multiple" && !isUpdate && (
              <Grid display="flex" mt={3}>
                <Grid flex={1} position="relative" display="flex">
                  <Grid width="100%">
                    <S3Input
                      type="text"
                      placeholder="Save Address"
                      file={file}
                      value={saveAddress}
                      onChange={(e) => setSaveAddress(e.target.value)}
                      disabled={file}
                    />
                  </Grid>
                  <StyledAddress file={file}>Save Address</StyledAddress>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Schedule Task
            </Grid>
            <Grid display="flex">
              <Grid display="flex" mr={9}>
                <input
                  type="radio"
                  id="now"
                  name="scheduleTask"
                  checked={scheduleTask === "now"}
                  onChange={() => setScheduleTask("now")}
                />
                <Grid ml={1}>
                  <label for="now">Now</label>
                </Grid>
              </Grid>
              <Grid display="flex">
                <input
                  type="radio"
                  id="custom"
                  name="scheduleTask"
                  checked={scheduleTask === "Scheduled"}
                  onChange={() => setScheduleTask("Scheduled")}
                />
                <Grid ml={1}>
                  <label for="custom">Schedule</label>
                </Grid>
              </Grid>
            </Grid>
            <Grid display="flex">
              <Grid display="flex" mr={2}>
                <Grid>
                  <input
                    style={{
                      width: "32px",
                      outline: "none",
                      height: "38px",
                    }}
                    disabled={scheduleTask === "now"}
                    value={scheduleTaskTime.hour}
                    onChange={(e) =>
                      setScheduleTaskTime({
                        ...scheduleTaskTime,
                        hour: e.target.value,
                      })
                    }
                    type="text"
                  />
                </Grid>
                <Grid mx={0.4}>:</Grid>
                <Grid>
                  <StyledInput
                    type="text"
                    disabled={scheduleTask === "now"}
                    value={scheduleTaskTime.minute}
                    onChange={(e) =>
                      setScheduleTaskTime({
                        ...scheduleTaskTime,
                        minute: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <LocalizationProvider
                sx={{ ".css-1uc06fq-MuiStack-root": { overflow: "hidden" } }}
                dateAdapter={AdapterDayjs}
              >
                <DemoContainer
                  sx={{ paddingTop: "0px" }}
                  components={["DatePicker"]}
                >
                  <DatePicker
                    sx={{
                      ".MuiInputBase-input": { padding: "8px" },
                    }}
                    disablePast
                    disabled={scheduleTask === "now"}
                    value={scheduleTaskDate}
                    onChange={(e) => setScheduleTaskDate(e)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Execution Type
            </Grid>
            <Grid display="flex">
              <Grid display="flex" mr={6} alignItems={"center"}>
                <input
                  type="radio"
                  id="period"
                  name="exeType"
                  checked={exeType === "period"}
                  onChange={() => setExeType("period")}
                />
                <Grid ml={1}>
                  <label for="period">Period</label>
                </Grid>
                <Grid ml={2}>
                  <FormControl size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={exePeriod}
                      disabled={exeType === "random"}
                      onChange={(e) => setExePeriod(e.target.value)}
                    >
                      <MenuItem value={"ONCE"}>Once</MenuItem>
                      <MenuItem value={"DAILY"}>Daily</MenuItem>
                      <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                      <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid display="flex" alignItems={"center"}>
                <input
                  type="radio"
                  id="random"
                  name="exeType"
                  checked={exeType === "random"}
                  onChange={() => setExeType("random")}
                />
                <Grid ml={1}>
                  <label for="random">Random</label>
                </Grid>
                <Grid ml={2}>
                  <FormControl size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={exeRandom}
                      disabled={exeType === "period"}
                      onChange={(e) => setExeRandom(e.target.value)}
                    >
                      <MenuItem value={"SELECT DAYS"}>Select Days</MenuItem>
                      <MenuItem value={"10"}>10</MenuItem>
                      <MenuItem value={"20"}>20</MenuItem>
                      <MenuItem value={"30"}>30</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid width="100%" display="flex" justifyContent={"center"} mt={4}>
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <Grid mr={2}>
                  <ModalButton
                    onClick={isEdit ? editJobData : fetchJobData}
                  >
                    {isUpdate ? "Update" : "Apply"}
                  </ModalButton>
                </Grid>

                <Grid>
                  <ModalButton
                    onClick={() => {
                      handleClose();
                      setIsEdit(false);
                    }}
                  >
                    Cancel
                  </ModalButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </ModalStyle>
    </Modal>
  );
};

export default ApplyModal;
