import { CircularProgress, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import {
  FileInput,
  FileInputLabel,
  ModalButton,
  ModalInner,
  ModalStyle,
  S3Input,
  StyledS3,
  StyledS3Text,
} from "./Style";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CREATE_TEMP_SUCCESS_MESSAGE, INVALID_TOKEN_MESSAGE } from "./Constant";
import { startTemplateApi } from "./Service";

export const CreateTemplateModal = ({
  open,
  handleClose,
  fetchTemplateList,
}) => {
  const [file, setFile] = useState(null);
  const [s3Link, setS3Link] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchTemplateData = async (e) => {
    const formData = new FormData();
    if (file) {
      formData.append("target_file", file);
      formData.append("service_type", "LOCAL_SYSTEM");
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        sessionStorage.setItem("upload-data", reader.result);
      };
    } else {
      formData.append("target_path", s3Link);
      formData.append("service_type", "S3");
    }
    try {
      setLoading(true);
      let response = await startTemplateApi({ formData });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(CREATE_TEMP_SUCCESS_MESSAGE);
        fetchTemplateList();
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <ModalStyle>
        <ModalInner>CREATE TEMPLATE</ModalInner>
        <Grid p={4}>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Upload File/s
            </Grid>

            <Grid display="flex">
              <Grid>
                <FileInputLabel s3Link={s3Link} for="upload-photo">
                  Local
                </FileInputLabel>
                <FileInput
                  type="file"
                  name="photo"
                  accept="application/pdf"
                  id="upload-photo"
                  disabled={s3Link}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>

              <Grid flex={1} position="relative" display="flex">
                <Grid width="100%">
                  <S3Input
                    type="text"
                    placeholder="S3 Link"
                    file={file}
                    value={s3Link}
                    onChange={(e) => setS3Link(e.target.value)}
                    disabled={file}
                  />
                </Grid>
                <StyledS3 file={file}>S3 Link</StyledS3>
                {file && <StyledS3Text>{file.name}</StyledS3Text>}
              </Grid>
            </Grid>
          </Grid>
          <Grid width="100%" display="flex" justifyContent={"center"} mt={4}>
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <Grid mr={2}>
                  <ModalButton
                    onClick={fetchTemplateData}
                    disabled={file === null && s3Link === null}
                  >
                    Create
                  </ModalButton>
                </Grid>

                <Grid>
                  <ModalButton onClick={handleClose}>Cancel</ModalButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </ModalStyle>
    </Modal>
  );
};
