import axios from "axios";
import { BASE_URL } from "../../../Utils";

export const getAllJobApi = async () => {
  return await axios.get(BASE_URL + "/job-scheduling/get-job", {
    headers: {
      authorization: sessionStorage.getItem("token"),
    },
  });
};

export const removeJobApi = async ({ job_id }) => {
  return await axios.post(
    BASE_URL + "/job-scheduling/delete-job",
    { job_id },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};

export const getJobApi = async ({ job_id }) => {
  return await axios.post(
    BASE_URL + "/job-scheduling/get-job",
    { job_id },
    {
      headers: {
        authorization: sessionStorage.getItem("token"),
      },
    }
  );
};

export const ApplyTemplateApi = async ({ formData }) => {
  return await axios.post(BASE_URL + "/job-scheduling/execute-job", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      authorization: sessionStorage.getItem("token"),
    },
  });
};

export const updateJobApi = async ({ formData }) => {
  return await axios.post(BASE_URL + "/job-scheduling/update-job", formData, {
    headers: {
      Accept: "application/json",
      authorization: sessionStorage.getItem("token"),
    },
  });
};