import {
  Checkbox,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { TableContext } from "./ExtractedComponents";

const DataTable = ({ setWorkflow, props }) => {
  let rows = [];
  let colmark = [];
  let cols = [];

  const {
    target_index,
    setTarget_index,
    editColsToggle,
    isTableEditMode,
    editRowsToggle,
    renameTarNames,
    setRenameTarNames,
    colCheckList,
    setColCheckList,
    rowCheckList,
    setRowCheckList,
  } = useContext(TableContext);

  props?.forEach((eachRow, index) => {
    index === 0 ? cols.push(eachRow) : rows.push(eachRow);
  });

  if (cols.length > 0) {
    colmark = cols[0].map((col, index) => (
    String.fromCharCode("A".charCodeAt(0) + index))
  ); }

  if (editRowsToggle && isTableEditMode) colmark.unshift("");

  const handleTargetIndex = (val) => {
    if (target_index.includes(parseInt(val))) {
      setTarget_index(target_index.filter((item) => item !== val));
    } else {
      setTarget_index([...target_index, val]);
    }
  };

  const handleRenameTarNames = (name) => {
    if (renameTarNames.includes(name)) {
      setRenameTarNames(renameTarNames.filter((item) => item !== name));
    } else {
      setRenameTarNames([...renameTarNames, name]);
    }
  };

  const checkboxHandler = (index) => {
    handleTargetIndex(index);
    handleRenameTarNames(cols[0][index]);
    let checklist = colCheckList;
    checklist[index].checked = !checklist[index].checked;
    setColCheckList(colCheckList);
  };

  const rowCheckboxHandler = (index) => {
    handleTargetIndex(index);
    let checklist = rowCheckList;
    checklist[index].checked = !checklist[index].checked;
    setRowCheckList(rowCheckList);
  };

  return (
    <>
      {/* <Button onClick={handleClick}>Edit Table</Button> */}
      <TableContainer component={Paper}>
        <Table
          stickyHeader={true}
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            {isTableEditMode && editColsToggle && (
              <TableRow>
                {colmark.map((mark, index) => (
                  <TableCell align="center" sx={{ padding: "0" }}>
                    <MenuItem>
                      <Checkbox
                        size="small"
                        checked={colCheckList[index]?.checked}
                        onChange={() => checkboxHandler(index)}
                        sx={{ margin: "0 auto" }}
                      ></Checkbox>
                    </MenuItem>
                  </TableCell>
                ))}
              </TableRow>
            )}
            <TableRow>
              {colmark.map((mark) => (
                <TableCell align="center">{mark}</TableCell>
              ))}
            </TableRow>
            {cols.map((col, index) => (
              <TableRow>
                {editRowsToggle && isTableEditMode && (
                  <TableCell align="center" sx={{ padding: 0 }}>
                    <Checkbox
                      size="small"
                      onChange={() => rowCheckboxHandler(index)}
                      sx={{ margin: "0 auto" }}
                      checked={rowCheckList[0]?.checked}
                    ></Checkbox>
                  </TableCell>
                )}
                {col.map((cell) => (
                  <TableCell
                    align="center"
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      color: "#ffffff",
                      background: "green",
                    }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow>
                {editRowsToggle && isTableEditMode && (
                  <TableCell align="center" sx={{ padding: "0" }}>
                    <Checkbox
                      size="small"
                      onChange={() => rowCheckboxHandler(index + 1)}
                      sx={{ margin: "0 auto" }}
                      checked={rowCheckList[index + 1]?.checked}
                    ></Checkbox>
                  </TableCell>
                )}
                {row.map((cell) => (
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DataTable;
