import React, { useState } from "react";
import { Grid } from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ViewErrorPopUp from "./ViewErrorPopUp";
import { COLS, SIMPLIFY_DATE, SIMPLIFY_STATUS } from "./Util";
import {
  ActionButton,
  ColCell,
  Row,
  RowContainer,
  RowFirst,
  RowSection,
  TableHead,
} from "./Style";
import { getTemplateApi } from "./Service";
import { INVALID_TOKEN_MESSAGE } from "./Constant";

const Templates = ({ templateData, handleRemoveTemplate }) => {
  const navigate = useNavigate();
  const [viewError, setViewError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInitiate = async (template_id, isUpdate = false) => {
    try {
      let response = await getTemplateApi({ template_id });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === INVALID_TOKEN_MESSAGE) {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        let state = {
          template_id: template_id,
          extracted_tables: response.data.template.extracted_tables,
          pair_feature_list: response.data.template.pair_feature_list,
          selectedTablesIds: response.data.template.selected_tables,
          selectedPairsIds: response.data.template.selected_pairs,
          pdf_file: response.data.template.pdf_file,
        };
        if (isUpdate) state = { ...state, flow: "UPDATE" };
        navigate("/create-template", {
          state,
        });
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  return (
    <Grid width="90%">
      <TableHead>
        {COLS.map((col) => (
          <ColCell width={col.WIDTH}>{col.HEADING}</ColCell>
        ))}
      </TableHead>
      <RowSection>
        {templateData?.map((temp) => (
          <RowContainer name={temp.template_id}>
            <RowFirst>{temp.template_name}</RowFirst>
            <Row>{SIMPLIFY_DATE(temp.created_at)}</Row>
            <Row>{SIMPLIFY_STATUS(temp.template_status)}</Row>
            <Row>
              <Grid fontSize={"16px"} fontStyle="italic" color="#4393f0">
                {temp.template_status === "COMPLETED" && (
                  <ActionButton
                    name={temp.id}
                    onClick={() => handleInitiate(temp.id, true)}
                  >
                    Update
                  </ActionButton>
                )}
                {(temp.template_status === "IN_PROGRESS" ||
                  temp.template_status === "INITIATED") && (
                  <ActionButton
                    name={temp.id}
                    onClick={() => handleInitiate(temp.id)}
                  >
                    Resume
                  </ActionButton>
                )}
                <ActionButton
                  name={temp.id}
                  onClick={(e) => handleRemoveTemplate(e)}
                >
                  Remove
                </ActionButton>
                {temp.template_status === "ERROR" && (
                  <ActionButton
                    name={temp.id}
                    onClick={() => {
                      setViewError(true);
                      setErrorMessage(temp.error_message);
                    }}
                  >
                    View Error
                  </ActionButton>
                )}
              </Grid>
            </Row>
          </RowContainer>
        ))}
      </RowSection>
      <ViewErrorPopUp
        open={viewError}
        setOpen={setViewError}
        error={errorMessage}
      />
    </Grid>
  );
};

export default Templates;
