import React from "react";
import TextModal from "./TextModal";
import ImageModal from "./ImageModal";
import TableModal from "./TableModal";
import { EXTRACTED_PARTS } from "./Util";

const ExtractionModal = ({ currentTab, data, setTarget_table }) => {
  const modal = () =>
    currentTab === EXTRACTED_PARTS.IMAGE ? (
      data.image_base64.length > 0 && <ImageModal props={data.image_base64} />
    ) : currentTab === EXTRACTED_PARTS.TEXT ? (
      <TextModal props={data.extracted_text} />
    ) : (
      data.length > 0 && (
        <TableModal props={data} setTarget_table={setTarget_table} />
      )
    );

  return <div>{modal()}</div>;
};

export default ExtractionModal;
