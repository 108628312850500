import React, { useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer = React.forwardRef(
  (
    {
      url,
      page,
      setIsDrawing,
      startPosition,
      setStartPosition,
      endPosition,
      setEndPosition,
      coordinates,
      setCoordinates,
      pageNumber,
      numPages,
      setNumPages,
      sendBboxCoordinates,
    },
    ref
  ) => {
    const canvasRef = useRef();
    const drawingRef = ref;
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function handleMouseDown(e) {
      let canvas = drawingRef.current;
      canvas.style.width = "0px";
      canvas.style.height = "0px";
      setEndPosition({ x: 0, y: 0 });
      var mousePos = getMousePosition(canvasRef.current, e);
      let data = [...coordinates];
      data[data.length] = {
        pageNumber: pageNumber - 1,
        customCoordinates: {
          x0: mousePos.x,
          y0: mousePos.y,
        },
      };
      setCoordinates(data);
      setIsDrawing(true);
      setStartPosition({ x: mousePos.x, y: mousePos.y });
    }

    function handleMouseUp(e) {
      var mousePos = getMousePosition(canvasRef.current, e);
      let data = [...coordinates];
      let customCoordinates = {
        x1: mousePos.x,
        y1: mousePos.y,
        x0: data[data.length - 1].customCoordinates.x0,
        y0: data[data.length - 1].customCoordinates.y0,
      };
      data[data.length - 1] = {
        ...data[data.length - 1],
        customCoordinates,
      };

      setCoordinates(data);
      setIsDrawing(false);
      setEndPosition({ x: mousePos.x, y: mousePos.y });
      sendBboxCoordinates(data);
    }

    function getMousePosition(canvas, e) {
      var boundary = canvas.getBoundingClientRect();
      return {
        x: e.clientX - boundary.left,
        y: e.clientY - boundary.top,
      };
    }

    useEffect(() => {
      if (startPosition && endPosition) {
        const div = drawingRef.current;
        div.style.backgroundColor = "rgba(0,0,0,0.1)";
        const width = endPosition.x - startPosition.x;
        const height = endPosition.y - startPosition.y;
        div.style.border = "2px solid red";
        div.style.width = width + "px";
        div.style.height = height + "px";
        div.style.top = Math.min(startPosition.y) + "px";
        div.style.left = Math.min(startPosition.x) + "px";
      }
    }, [startPosition, endPosition]);

    return (
      <div
        ref={canvasRef}
        onMouseDown={page === "drag" ? handleMouseDown : null}
        onMouseUp={page === "drag" ? handleMouseUp : null}
        style={{ width: "fit-content", position: "relative" }}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            canvasRef={canvasRef}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <div style={{ position: "absolute" }} ref={drawingRef}></div>
      </div>
    );
  }
);
