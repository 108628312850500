import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ServiceHeading, StyledButton } from "./Style";
import { SERVICE_LIST } from "./Util";

const Service = () => {
  const navigate = useNavigate();

  return (
    <Grid width={"100%"}>
      <ServiceHeading>Choose a Service</ServiceHeading>
      <Grid>
        {SERVICE_LIST.map((service) => (
          <Grid my={2.5}>
            <StyledButton onClick={() => navigate(service.ROUTE)}>
              {service.TEXT}
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Service;
