import { Grid } from "@mui/material";
import React from "react";
import UPLOAD_LOGO from "../../../Assets/Icons/inbox-upload.svg";
import DRAGDROP_LOGO from "../../../Assets/Icons/drag-and-drop.svg";
import INPUT_LOGO from "../../../Assets/Icons/input.svg";
import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";
import SHARE_LOGO from "../../../Assets/Icons/share.svg";

const stepGuideList = [
  {
    icon: DRAGDROP_LOGO,
    desciption:
      "Drag & drop your PDF into the white box or use the corresponding button for the upload.",
  },
  {
    icon: INPUT_LOGO,
    desciption: "The process of extracting will start automatically.",
  },
  {
    icon: DOWNLOAD_LOGO,
    desciption:
      " When done, download all Images, Text and Tables in relevant tabs.",
  },
  {
    icon: SHARE_LOGO,
    desciption: "You have the option of sharing the results right away.",
  },
];

const HowTo = () => {
  return (
    <Grid
      py={10}
      pl={20}
      sx={{ background: "#003AA6 0% 0% no-repeat padding-box" }}
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
    >
      <Grid display={"flex"} alignItems="center" mb={2}>
        <Grid mr={3}>
          <img src={UPLOAD_LOGO} width={"50"} height={"50"} alt="#" />
        </Grid>
        <Grid
          fontWeight={700}
          fontSize={"32px"}
          lineHeight={"70px"}
          letterSpacing={0}
          color={"#ffffff"}
          textTransform={"uppercase"}
        >
          HOW TO EXTRACT CONTENT FROM PDF
        </Grid>
      </Grid>
      <Grid>
        {stepGuideList.map((step) => (
          <Grid display={"flex"} alignItems="center" my={2}>
            <Grid mr={2.5} ml={1}>
              <img src={step.icon} alt="#" width={"30"} height={"30"} />
            </Grid>
            <Grid
              lineHeight={"28px"}
              fontWeight="400"
              fontSize={"18px"}
              color="#ffffff"
            >
              {step.desciption}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default HowTo;
