import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TABLE_SELECT_LOGO from "../../../Assets/Icons/table-select.svg";
import BACK_ARROW_LOGO from "../../../Assets/Icons/back-arrow.svg";
import { ExtractorButton, ExtractorButtonImage } from "./Style";
import DataTable from "./DataTable";
import {
  CreateFlowButton,
  CustomKeyButton,
  CustomKeyModalButton,
  CustomKeySelection,
  ModalContainer,
  NavsDiv,
  PdfNavs,
  SidebarContainer,
  StyledKeysContainer,
  StyledKeysInner,
  StyledPdfContainer,
} from "./Style";
import axios from "axios";
import { toast } from "react-hot-toast";
import { PDFViewer } from "./PDFViewer";
import { BASE_URL } from "../../../Utils";
import { create } from "yup/lib/Reference";
import { MessageModal } from "./MessageModal";

export const ExtractedKeyView = ({
  setStepIndex,
  extKeysArr,
  setExtKeysArr,
  selKeysArr,
  setSelKeysArr,
  pairFeatureList,
  getPairFeatureData,
  pdfFile,
}) => {
  const navigate = useNavigate();
  let location = useLocation();
  const templateID = location?.state?.template_id;
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const getExtractedKeys = async () => {
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/get-template",
        { template_id: templateID },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        const pairFeatureList = response.data.template.pair_feature_list;
        const selectedPairIds = response.data.template.selected_pairs;
        const getPairFeatureData = () => {
          let arr = [];
          pairFeatureList?.forEach((pairArr) => {
            pairArr.forEach((item) => {
              arr.push({
                id: item.pair_id,
                key: item.target_key,
                value: item.target_value,
              });
            });
          });
          return arr;
        };
        let selsArr = [];
        let extArr = [];
        getPairFeatureData().forEach((pair) => {
          if (selectedPairIds.includes(pair.id)) {
            selsArr.push(pair);
          } else {
            extArr.push(pair);
          }
        });
        setExtKeysArr(extArr);
        setSelKeysArr(selsArr);
        // setStepIndex(1);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };

  useEffect(() => {
    getExtractedKeys();
  }, []);

  const extKeyChangeHandler = (clickedKey) => {
    let newExt = [];
    let newSel = [];
    extKeysArr.map((pair) =>
      pair.id === clickedKey ? newSel.push(pair) : newExt.push(pair)
    );
    newSel = [...newSel, ...selKeysArr];
    setSelKeysArr(newSel);
    setExtKeysArr(newExt);
  };

  const selKeyChangeHandler = (clickedKey) => {
    let newExt = [];
    let newSel = [];
    selKeysArr.map((pair) =>
      pair.id === clickedKey ? newExt.push(pair) : newSel.push(pair)
    );
    newExt = [...newExt, ...extKeysArr];
    setSelKeysArr(newSel);
    setExtKeysArr(newExt);
  };
  const handleSaveSelectedPair = async (redirect) => {
    const user = sessionStorage.getItem("token");

    let pairIds = selKeysArr.map((value) => {
      return value.id;
    });
    let payload = {
      template_id: templateID,
      pair_ids: pairIds,
    };
    try {
      let response = await axios.post(
        BASE_URL + "/template-extraction/save-selected-pair",
        payload,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success(response.data.message);
        redirect && setStepIndex(2);
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  return (
    <Grid display={"flex"} width="100%" marginTop="65px">
      {pairFeatureList &&
      pairFeatureList.length > 0 &&
      pairFeatureList[0].length > 0 ? (
        <>
          <Grid sx={{ minWidth: "35%" }}>
            <SidebarContainer>
              <Grid
                fontSize={"18px"}
                color="#ffffff"
                mb={1.2}
                textAlign={"center"}
              >
                Extracted Keys
              </Grid>
              <div
                style={{
                  overflow: "hidden",
                  borderRadius: "4px",
                  padding: "10px 10px 10px 10px",
                  background: "white",
                  width: "70%",
                  margin: "0 auto"
                }}
              >
                <Grid
                  height="180px"
                  bgcolor={"#ffffff"}
                  width="100%"
                  sx={{
                    overflowY: "auto",
                    border: "1px solid black",
                  }}
                  className="create-template-scrollbar"
                >
                  {extKeysArr.map((pair) => (
                    <CustomKeySelection
                      onDoubleClick={() => extKeyChangeHandler(pair.id)}
                    >
                      <Grid width="50%" p={0.4}>
                        {pair.key}
                      </Grid>
                      <Grid width="50%" p={0.4} borderLeft={"1px solid black"}>
                        {pair.value}
                      </Grid>
                    </CustomKeySelection>
                  ))}
                </Grid>
              </div>

              <Grid
                fontSize={"18px"}
                color="#ffffff"
                mb={1.2}
                mt={2}
                textAlign={"center"}
              >
                Selected Keys
              </Grid>
              <StyledKeysContainer>
                <StyledKeysInner>
                  {selKeysArr.map((pair) => (
                    <CustomKeySelection
                      onDoubleClick={() => selKeyChangeHandler(pair.id)}
                    >
                      <Grid width="50%" p={0.4}>
                        {pair.key}
                      </Grid>
                      <Grid width="50%" p={0.4} borderLeft={"1px solid black"}>
                        {pair.value}
                      </Grid>
                    </CustomKeySelection>
                  ))}
                </StyledKeysInner>
              </StyledKeysContainer>
            </SidebarContainer>
          </Grid>
          <Grid flex={1} background="#f9f9f9" width="65%">
            <Grid p={3}>
              <Grid display={"flex"} justifyContent="center" mb={2}>
                <CreateFlowButton>TEMPLATE CREATION</CreateFlowButton>
              </Grid>
              <StyledPdfContainer>
                <NavsDiv>
                  <PdfNavs
                    disabled={pageNumber === 1}
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    Prev
                  </PdfNavs>
                  <PdfNavs
                    disabled={pageNumber === numPages}
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    Next
                  </PdfNavs>
                </NavsDiv>

                <PDFViewer
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  url={`data:application/pdf;base64,${pdfFile.split("'")[1]}`}
                />
              </StyledPdfContainer>
              <Grid
                display="flex"
                justifyContent={"center"}
                alignItems="center"
                flexDirection="column"
              >
                <Grid display="flex" mt={1}>
                  <ExtractorButton
                    variant="contained"
                    size="small"
                    width="150px"
                    onClick={() => {
                      setStepIndex(0);
                      setSelKeysArr([]);
                      setExtKeysArr(getPairFeatureData());
                    }}
                  >
                    <ExtractorButtonImage
                      src={BACK_ARROW_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    Back
                  </ExtractorButton>
                  <ExtractorButton
                    variant="contained"
                    size="small"
                    onClick={() => handleSaveSelectedPair(false)}
                  >
                    <ExtractorButtonImage
                      src={BACK_ARROW_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    Save Changes
                  </ExtractorButton>
                  <ExtractorButton
                    variant="contained"
                    size="small"
                    onClick={() => handleSaveSelectedPair(true)}
                  >
                    <ExtractorButtonImage
                      src={BACK_ARROW_LOGO}
                      alt="#"
                      width={"12"}
                      height="12"
                    />
                    Save & Continue
                  </ExtractorButton>
                </Grid>
              </Grid>
            </Grid>
            {}
          </Grid>
        </>
      ) : (
        <MessageModal
          modalHeading={"No extracted key-pairs for this PDF."}
          saveHandler={() => handleSaveSelectedPair(true)}
        />
      )}
    </Grid>
  );
};
