import Styled from "styled-components";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid } from "@mui/material";

export const CustomKeyButton = styled(Button)({
  background: "#02225e",
  color: "#ffffff",
  boxShadow: "0px 3px 6px #02225e",
  borderRadius: "20px",
  width: "160px",
  margin: "0 10px",
  fontSize: "12px",
});

export const CustomKeyModalButton = styled(Button)({
  background: "#003AA6",
  color: "#ffffff",
  borderRadius: "20px",
  width: "100px",
  margin: "0 6px",
  fontSize: "12px",
});

export const ModalContainer = Styled.div`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 10px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  width: 400px;
`;

export const FileInput = Styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
export const FileInputLabel = Styled.label`
  cursor: pointer;
  border-radius: 20px;
  border:${(props) =>
    props.s3Link ? "1px solid #d1cfcf" : "1px solid #003AA6"};
  line-height:34px;
  background: ${(props) => (props.s3Link ? "#d1cfcf" : "#003AA6")};
  color:#fff;
  margin-right:16px;
   width:90px;
   text-align:center;
`;
export const S3Input = Styled.input`
outline:none;
border-radius:20px;
color:#003AA6;
padding-left:10px;
border:${(props) => (props.file ? "1px solid #d1cfcf" : "1px solid #003AA6")};
width:100%;

line-height:34px;
background: ${(props) => (props.file ? "#d1cfcf" : "none")};
`;
export const RefreshBtn = Styled.button`
position: absolute;
    right: 190px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const ModalButton = Styled.button`
display: block;
  width: 150px;
  line-height: 2;
  font-size: 12px;
  background-color: #003aa6;
  color: #fff;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 3px 6px #00000052;
  user-select: none;
  margin: 0 auto;
`;

export const BackBtn = Styled.button`
position: absolute;
    right: 100px;
    top: 120px;
    display: block;
    width: 80px;
    line-height: 2;
    background-color: #003AA6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border:none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
`;

export const RecordContainer = styled(Grid)({
  margin: "125px auto",
});

export const ModalStyle = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "12px",
  width: "700px",
});

export const SidebarContainer = styled(Grid)({
  width: "100%",
  height: "89.5vh",
  background: "#003AA6",
  paddingTop: "32px",
  paddingBottom: "16px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: "0 16px 16px 0",
});

export const SidebarTab = styled(Grid)({
  fontSize: "20px",
  color: "#ffffff",
  background: (props) => (props.bgcolor ? props.bgcolor : "auto"),
  paddingLeft: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  cursor: "pointer",
});

export const StyledButton = Styled.button`
    display: block;
    width: 270px;
    line-height: 2;
    background-color: #003aa6;
    color: #fff;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 3px 6px #00000052;
    user-select: none;
    margin: 0 auto;
  `;

export const CreateFlowButton = styled(Grid)({
  border: "2px solid #003AA6",
  background: "#003AA6",
  color: "#ffffff",
  paddingLeft: "48px",
  paddingRight: "48px",
  borderRadius: "18px",
  fontSize: "18px",
});

export const StyledPdfContainer = styled(Grid)({
  height: "66vh",
  width: "95%",
  margin: "0 auto",
  overflowY: "auto",
  marginBottom: "16px",
  border: "1px solid black",
});

export const PageTab = styled(Grid)`
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
  border: ${(props) =>
    props.page.isOpen ? "1px solid black" : "1px solid white"};
  border-radius: 3px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

export const PageTabSubSection = styled(Grid)({
  color: "#003AA6",
  background: "#09097c",
  padding: "16px",
  border: "1px solid blue",
  borderRadius: "10px",
});

export const StyledKeysContainer = Styled.div`
overflow: hidden;
border-radius: 4px;
padding: 10px 10px 10px 10px;
background: #fff;
width: 70%;
margin: 0 auto;
`;

export const StyledKeysInner = styled(Grid)({
  height: "180px",
  bgcolor: "#ffffff",
  width: "100%",
  padding: "2px",
  overflowY: "auto",
  cursor: "pointer",
  border: "1px solid black",
});

export const PageContainer = styled(Grid)({
  background: "#003AA6",
  cursor: "pointer",
  marginTop: "4px",
  marginBottom: "4px",
  border: "1px solid #003AA6",
  color: "white",
  paddingLeft: "16px",
  paddingRight: "16px",
});

export const SideBarText = styled(Grid)({
  fontSize: "18px",
  color: "#ffffff",
  marginBottom: "24px",
  textAlign: "center",
});

export const StyledContainer = styled(Grid)({
  display: "flex",
  width: "100%",
  marginTop: "65px",
  overflow: "hidden",
  height: "89.5vh",
});

export const InnerContainer = styled(Grid)({
  minWidth: "18%",
  display: "flex",
  justifyContent: "space-between",
  overflow: "hidden",
});

export const ActionButton = Styled.button`
  border: none;
  background: transparent;
  font-size: 16px;
  font-style: italic;
  color: #4393f0;
`;

export const Row = styled(Grid)({
  fontSize: "18px",
  width: "250px",
  marginRight: "8px",
});

export const RowFirst = styled(Grid)({
  fontSize: "18px",
  width: "600px",
  marginRight: "8px",
  wordBreak: "break-word",
});

export const RowContainer = styled(Grid)({
  display: "flex",
  width: "90%",
  marignTop: "8px",
  paddingBottom: "8px",
  borderBottom: "1px solid black",
  margin: "0 auto",
});

export const TableHead = styled(Grid)({
  fontSize: "24px",
  fontWeight: "600",
  display: "flex",
  width: "90%",
  margin: "0 auto",
});

export const ColCell = styled(Grid)({
  marginBottom: "16px",
  marginRight: "8px",
  width: (props) => (props.width ? props.width : "auto"),
});

export const RowSection = styled(Grid)({
  height: "300px",
  overflow: "auto",
});

export const ModalInner = styled(Grid)({
  background: "#003AA6",
  color: "#fff",
  paddingTop: "6px",
  paddingBottom: "6px",
  textAlign: "center",
  borderRadius: "12px 12px 0 0",
});

export const StyledS3 = styled(Grid)`
  border: ${(props) =>
    props.file ? "1px solid #868282" : "1px solid #003AA6"};
  background: ${(props) => (props.file ? "d1cfcf" : "#003AA6")};
  color: ${(props) => (props.file ? "#000" : "#fff")};
  border-radius: 20px;
  line-height: 36px;
  width: 90px;
  text-align: center;
  position: absolute;
  right: 0px;
`;

export const StyledS3Text = styled(Grid)({
  position: "absolute",
  top: "45px",
  left: "-125px",
  fontSize: "10px",
  color: "green",
});

export const CustomKeyContainer = styled(Grid)({
  height: "380px",
  background: "#ffffff",
  width: "70%",
  overflowY: "auto",
  borderRadius: "10px",
  padding: "10px",
  margin: "0 auto",
});

export const PdfViewContainer = styled(Grid)({
  height: "66vh",
  width: "95%",
  margin: "0 auto",
  overflowY: "auto",
  marginBottom: "16px",
  border: "1px solid black",
});

export const PdfNavs = Styled.button`
  border-radius: 8px;
  height: 2rem;
  width: 3rem;
  margin-left: 1rem;
  color: white;
  border: none;
  background-color: #003aa6;
  cursor: pointer;
&:disabled {
  background-color: gray;
  cursor: auto;
}
`;

export const NavsDiv = Styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px
`;

export const CustomKeySelection = styled(Grid)`
  display: flex;
  border-bottom: 1px solid black;
  cursor: pointer;
  &:first-child {
    color: white;
    background-color: blue;
  }
  &:hover {
    background-color: rgba(81, 176, 248, 0.231);
  }
`;

export const ExtractorButton = styled(Button)`
  background: #003aa6;
  color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  width: ${(props) => (props.width ? props.width : "180px")};
  margin: 0 10px;
`;

export const ExtractorButtonImage = Styled.img`
  margin-right:10px
  `;
