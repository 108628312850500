import { Grid } from "@mui/material";
import React from "react";
import SAFETY_LOGO from "../../../Assets/Icons/Safety.svg";
import COMPATIBLE_LOGO from "../../../Assets/Icons/compatible.svg";
import ONLINE_LOGO from "../../../Assets/Icons/online.svg";
import HEXA_LOGO from "../../../Assets/Icons/Logo_Hexaview.png";

const headerList = [
  {
    icon: SAFETY_LOGO,
    desciption: "Safe & Secure",
  },
  {
    icon: ONLINE_LOGO,
    desciption: "Online & Hassle Free",
  },
  {
    icon: COMPATIBLE_LOGO,
    desciption: "Universally Compatible",
  },
];

const Header = () => {
  return (
    <Grid width={"42%"} mr={15}>
      <Grid
        color="#2089E5"
        fontWeight={"700"}
        fontSize="32px"
        lineHeight={"40px"}
        mb={2}
      >
        Get Your Desired Content Extracted Seamlessly From Any PDF!
      </Grid>
      <Grid
        mb={2}
        fontSize={"18px"}
        fontWeight={400}
        color="#000000"
        width={"70%"}
        ml={0.4}
      >
        With this online tool, you can extract Images, Text or tables from a PDF
        File. No installation is required.
      </Grid>
      <Grid width="100%" textAlign={"center"} ml={2} my={3}>
        {headerList.map((item) => (
          <Grid display="flex" alignItems={"center"} my={2}>
            <img src={item.icon} alt="#" width={"35"} height={"35"} />{" "}
            <Grid ml={1.5} fontSize={"20px"} fontWeight="600">
              {item.desciption}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        fontSize={"18px"}
        fontWeight={400}
        color="#000000"
        display={"flex"}
        alignItems="center"
        mt={6.5}
      >
        Accelerator tool by
        <Grid mx={1}>
          <img src={HEXA_LOGO} alt="#" width={"130"} />
        </Grid>{" "}
      </Grid>
    </Grid>
  );
};

export default Header;
