import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_FILTERS } from "./Util";
import moment from "moment/moment";

const Pdf = ({ pdfData, handleRemovePdf }) => {
  const navigate = useNavigate();

  const simplifyPdfStatus = (status) => DASHBOARD_FILTERS[status];


  const handleView = async (id) => {
    try {
      let response = await axios.post(
        BASE_URL + "/extract/getpdfdata",
        { id },
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        navigate("/components", { state: response.data });
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  return (
    <Grid width="90%" height="89.4vh" overflow={"auto"}>
      <Grid
        fontSize={"24px"}
        fontWeight="600"
        display="flex"
        justifyContent={"flex-start"}
        width="90%"
        margin="0 auto"
      >
        <Grid mb={2} mr={1} width="400px">
          Pdf Name
        </Grid>
        <Grid mb={2} mr={1} width="250px">
          Creation Date
        </Grid>
        <Grid mb={2} mr={1} width="250px">
          Status
        </Grid>
        <Grid mb={2} mr={1} width="250px">
          Action
        </Grid>
      </Grid>
      {pdfData?.map((temp) => (
        <Grid
          display="flex"
          width="90%"
          mt={1}
          pb={1}
          borderBottom="1px solid black"
          margin="0 auto"
          name={temp.template_id}
        >
          <Grid
            fontSize={"18px"}
            width="400px"
            mr={1}
            sx={{ wordBreak: "break-word" }}
          >
            {temp.pdf_file_name}
          </Grid>

          <Grid fontSize={"18px"} width="250px" mr={1}>
            {moment(temp.created_at.split("T")[0]).format('DD MMMM YYYY')}
          </Grid>
          <Grid fontSize={"18px"} width="250px" mr={1}>
            {simplifyPdfStatus(temp.pdf_status)}
          </Grid>

          <Grid display="flex" width="250px" mr={1}>
            <Grid fontSize={"16px"} fontStyle="italic" color="#4393f0">
              {temp.pdf_status !== "IN_PROGRESS" && (
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    fontSize: "16px",
                    fontStyle: "italic",
                    color: "#4393f0",
                  }}
                  name={temp.id}
                  onClick={() => handleView(temp.id)}
                >
                  View
                </button>
              )}
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  fontSize: "16px",
                  fontStyle: "italic",
                  color: "#4393f0",
                }}
                name={temp.id}
                onClick={(e) => handleRemovePdf(e)}
              >
                Remove
              </button>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Pdf;
