import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { LogoutButton, StyledAppBar } from "./Styles";
import HEXA_LOGO from "../../../Assets/Icons/Logo_Hexaview.png";
import MAIN_LOGO from "../../../Assets/Icons/navbar.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import Menu from "@mui/material/Menu";
import { toast } from "react-hot-toast";

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  let open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = async () => {
    try {
      let response = await axios.get(`${BASE_URL}/logout`, {
        headers: {
          authorization: sessionStorage.getItem("token"),
        },
      });
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
      }
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
    }
  };
  let userName = sessionStorage.getItem("name");
  return (
    <StyledAppBar>
      <Container>
        <Toolbar>
          <Grid onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
            <Grid>
              <img src={MAIN_LOGO} alt="#" width={"200"} />
            </Grid>
            <Grid fontSize={"10px"} textAlign="right" mt={0.4}>
              Accelerator Tool by <img src={HEXA_LOGO} alt="#" width={"60"} />
            </Grid>
          </Grid>
          <Grid display={"flex"} marginLeft="auto" alignItems={"center"}>
            {userName && (
              <div>
                <Button
                  sx={{ color: "black", textTransform: "capitalize" }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={
                    window.location.pathname === "/dashboard/profile"
                      ? null
                      : handleClick
                  }
                >
                  Hi, {userName}
                </Button>
                {window.location.pathname === "/dashboard/profile" ? null : (
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/dashboard/profile");
                        handleClose();
                      }}
                    >
                      Profile
                    </MenuItem>
                  </Menu>
                )}
              </div>
            )}

            {sessionStorage.getItem("token") && (
              <LogoutButton onClick={logoutHandler}>Logout</LogoutButton>
            )}
          </Grid>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};
export default Navbar;
