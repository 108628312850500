import { Box, CircularProgress, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import { FileInput, FileInputLabel, ModalButton, S3Input } from "./Style";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../Utils";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: '#fff',
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "12px",
  width: "700px",
};

const ExtractPdf = ({ open = true, handleClose, fetchPdfList }) => {
  const [file, setFile] = useState(null);
  // const [open, setOpen] = useState(true);
  const [s3Link, setS3Link] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchExtractionData = async () => {
    const formData = new FormData();
    if (file) {
      formData.append("target_file", file);
      formData.append("service_type", "LOCAL_SYSTEM");
    } else {
      formData.append("target_file", s3Link);
      formData.append("service_type", "S3");
    }
    try {
      setLoading(true);
      let response = await axios.post(
        BASE_URL + "/extract/extract-pdf",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.data.error) {
        let message = response.data.message;
        toast.error(message);
        if (message === "Invalid Access Token.. Please login") {
          sessionStorage.clear();
          navigate("/");
          window.location.reload();
        }
      } else {
        toast.success("Pdf scheduled successfully.");
        fetchPdfList();
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      let message = error.response.data.error;
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // onClose={handleClose}
    >
      <Box sx={style}>
        <Grid
          fontSize={"22px"}
          bgcolor="#003AA6"
          py={0.5}
          color="#fff"
          borderRadius={"12px 12px 0 0"}
          textAlign="center"
        >
          EXTRACT PDF
        </Grid>
        <Grid p={4}>
          <Grid width="100%" mb={2}>
            <Grid fontWeight={"600"} mb={0.5}>
              Upload File/s
            </Grid>

            <Grid display="flex">
              <Grid>
                <FileInputLabel s3Link={s3Link} for="upload-photo">
                  Local
                </FileInputLabel>
                <FileInput
                  type="file"
                  accept="application/pdf"
                  name="photo"
                  id="upload-photo"
                  disabled={s3Link}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Grid>

              <Grid flex={1} position="relative" display="flex">
                <Grid width="100%">
                  <S3Input
                    type="text"
                    placeholder="S3 Link"
                    file={file}
                    value={s3Link}
                    onChange={(e) => setS3Link(e.target.value)}
                    disabled={file}
                  />
                </Grid>
                <Grid
                  border={file ? "1px solid #868282" : "1px solid #003AA6"}
                  bgcolor={file ? "d1cfcf" : "#003AA6"}
                  color={file ? "#000" : "#fff"}
                  borderRadius={"20px"}
                  lineHeight="36px"
                  width="90px"
                  textAlign={"center"}
                  position={"absolute"}
                  right="0px"
                >
                  S3 Link
                </Grid>
                {file && (
                  <Grid
                    position="absolute"
                    top="45px"
                    left="-125px"
                    fontSize="10px"
                    color="green"
                  >
                    {file.name}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid width="100%" display="flex" justifyContent={"center"} mt={4}>
            {loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                <Grid mr={2}>
                  <ModalButton onClick={fetchExtractionData}>
                    Extract
                  </ModalButton>
                </Grid>

                <Grid>
                  <ModalButton onClick={handleClose}>
                    Cancel
                  </ModalButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ExtractPdf;
