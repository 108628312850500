import {
    Box,
    Grid,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import { TextareaAutosize } from '@mui/material';
  import React, { useState } from "react";
  import { ExtractorButton, ExtractorButtonImage} from "./Style";
  import { CSVLink } from "react-csv";
  import DOWNLOAD_LOGO from "../../../Assets/Icons/Solid.svg";
  import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";  import axios from "axios";
  import { toast } from "react-hot-toast";
  import { BASE_URL } from "../../../Utils";
  import { useNavigate } from "react-router-dom";

  export default function EditTableText( {colsIn, rowsIn, final_table_id, table_id}) {
    const columns = [...colsIn[0]]
    const [tableData, setTableData] = useState(rowsIn);
    const [columnData, setColumnData] = useState(columns);
    const [editableCell, setEditableCell] = useState({ rowIndex: -1, colIndex: -1 });
    const [onDoubleClick, setOnDoubleClick] = useState(false);
    const navigate = useNavigate();

    const handleDoubleClick = (rowIndex, colIndex) => {
      setOnDoubleClick(!onDoubleClick)
      if (rowIndex === -1) {
        // Table head cell
        setEditableCell({ rowIndex, colIndex: colIndex });
      } else {
        // Regular cell
        setEditableCell({ rowIndex, colIndex });
      }
    };
  
    const handleChange = (e) => {
      const newValue = e.target.value;
      if (editableCell.rowIndex === -1 && editableCell.colIndex !== -1) {
        setTableData((prevData) => {
          const newColumns = [...columns];
          newColumns[editableCell.colIndex] = newValue;
          return prevData;
        });
        setColumnData((prevColumns) => {
          const newColumns = [...prevColumns];
          newColumns[editableCell.colIndex] = newValue;
          return newColumns;
        });
      } else if (editableCell.rowIndex !== -1 && editableCell.colIndex !== -1) {
        setTableData((prevData) => {
          const newData = [...prevData];
          newData[editableCell.rowIndex][editableCell.colIndex] = newValue;
          return newData;
        });
      }
  }

  const backHandler = () =>{
    navigate(-1)
  }
  
    const handleBlur = () => {
      setEditableCell({ rowIndex: -1, colIndex: -1 });
    };

    const handleSaveChanges = async () => {
      const  payload = {
        // 'jobresult' : [
        // {
          'table_id' : table_id,
          'final_table_id': final_table_id,
          'updated_table' : [
                [...columnData],
                ...tableData
          ]
        // }
      // ],
      
    };

      try {
        let response = await axios.post(
          BASE_URL + "/job-scheduling/save-job-result",
          payload,
          {
            headers: {
              authorization: sessionStorage.getItem("token"),
            },
          }
        );
        if (response.data.error) {
          let message = response.data.message;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            sessionStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          toast.success("Changes Saved Successfully");
        }
      } catch (error) {
        let message = error.response.data.error;
        toast.error(message);
      }
    };

      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        width: "1200px",
        height: "500px",
      };

    return(
        <>
        <Box sx={style}>
        <Grid
            display={"flex"}
            justifyContent="end"
            alignItems={"center"}
            mb={2}
          >
            <Grid
              textAlign={"center"}
              fontSize="20px"
              fontWeight={"600"}
              width="90%"
            >
              Table Result
            </Grid>
              <Grid onClick={backHandler} sx={{ cursor: "pointer" }}>
                <ArrowBackIosNewIcon /> Back
              </Grid>
          </Grid>
         <>
         
         <TableContainer component={Paper} sx={{ height: "400px" }}>
              <Table
                stickyHeader={true}
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {columnData.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sx={{
                          border: "1px solid rgba(224, 224, 224, 1)",
                          color: "#ffffff",
                          background: "green",
                        }}
                        onDoubleClick={() => handleDoubleClick(-1, colIndex)}
                      >
                        {editableCell.rowIndex === -1 && editableCell.colIndex === colIndex ? (
                          <TextareaAutosize
                            value = {column}
                            sx={{width:'100%', resizeBy:'auto'}}
                            // multiline
                            // className={`${onDoubleClick} ? classes.wrapText : ''`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoFocus
                          />
                        ) : (
                          column
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, rowIndex) => (
                    <TableRow>
                      {row?.map((cell, colIndex) => (
                      <TableCell
                      key={colIndex}
                      sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        color: "black",
                        width: '50px'
                      }}
                      // className={`${onDoubleClick} ? classes.wrapText : ''`}
                      onDoubleClick={() => handleDoubleClick(rowIndex, colIndex)}
                    >
                      {editableCell.rowIndex === rowIndex && editableCell.colIndex === colIndex ? (
                        <TextareaAutosize
                        sx={{width:'45px', height:'20px'}}
                          value={tableData[rowIndex][colIndex]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoFocus
                        />
                      ) : (
                        cell
                      )}
                    </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
         </>
         <Grid mt={3} marginLeft={"40%"} mb={3}>
            <CSVLink
              data = {tableData}
              headers={columnData}
              style={{ textDecoration: "none" }}
            >
              <ExtractorButton variant="contained">
                <ExtractorButtonImage
                  src={DOWNLOAD_LOGO}
                  alt="#"
                  width={"12"}
                  height="12"
                />
                Download
              </ExtractorButton>
              </CSVLink>
              <ExtractorButton 
                variant="contained" 
                onClick={handleSaveChanges}
              >
                Save Changes
              </ExtractorButton>
          </Grid>
        </Box>
        </>
    )
  }

